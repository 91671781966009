import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import textUnderline from "../../assets/images/text-underline.png";
import heroImg from "../../assets/images/hero-img.png";

export default () => {
  return (
    <div class="hero-section-wrap position-relative">
      <Container className="text-center">
        <div className="hero-sec">
          <h1>Arabic Screenwriting</h1>
          <div className="text-end">
            <img src={textUnderline} className="img-fluid me-5" alt="img"></img>
          </div>

          <h2 className="mt-md-5 mt-2 mb-md-5 mb-2">
            Plan, write, and share with the industry-standard software used by
            over 1 million screenwriters, TV shows, and blockbusters
          </h2>
          <Button
            variant="primary"
            className="btn-xl mb-3"
            href="/script/#_create_account"
          >
            Start Writing Now
          </Button>
          <p>
            3 free projects, no watermarks or page limits, no credit card
            required
          </p>
        </div>
        <img
          src={heroImg}
          className="img-fluid hero-main-img mt-5"
          alt="img"
        ></img>
      </Container>
    </div>
  );
};
