import React from "react";
import Slide from "../../components/Slide";
import {
  // Jumbotron,
  // Button,
  // Container,
  Row,
  Col,
  Image,
  // Badge,
  // Collapse,
} from "react-bootstrap";
import typewriter from "./action-adult-analog-1043512.jpg"; // Tell webpack this JS file uses this image
import { useResetScroll } from "../../utils";

export default (props) => {
  useResetScroll();

  return (
    <Slide className="pt-100">
      <h3 className="font-weight-bold text-center mb-5">
        We help screenwriters and filmmakers create better. So can you!
      </h3>

      <Row>
        <Col lg={6}>
          <Image src={typewriter} fluid />
        </Col>
        <Col lg={6}>
          {/* <div class="content">
            <div class="Action">
              Currently hiring:{" "}
              <a href="careers/support">
                <b>Customer Support Specialist Contractor</b>
              </a>{" "}
              (remote)
            </div>
          </div> */}
          <div class="content">
            <div class="Action">
              Currently hiring:{" "}
              <a href="careers/devops">
                <b>Senior DevOps Engineer</b>
              </a>{" "}
              (remote)
            </div>
          </div>
          <div class="content">
            <div class="Action">
              Currently hiring:{" "}
              <a href="careers/frontend">
                <b>Senior Front-end Engineer</b>
              </a>{" "}
              (remote)
            </div>
          </div>
          {/*
          <div class="content">
            <div class="Action">
              Currently hiring:{" "}
              <a href="careers/support">
                <b>
                  Customer Support Specialist Contractor (Nights &amp; Weekends)
                </b>
              </a>{" "}
              (remote)
            </div>
          </div>
        */}
        </Col>
      </Row>
    </Slide>
  );
};
