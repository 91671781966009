import React, { useState } from "react";
import { Collapse, Button } from "react-bootstrap";
import { ChevronRight } from "react-bootstrap-icons";
import style from "./style.scss";

export default ({ label, children }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="question-group">
      <h5
        className="question font-weight-semi-bold"
        onClick={() => setOpen(!isOpen)}
        aria-controls="example-collapse-text"
        aria-expanded={isOpen}
      >
        <ChevronRight
          className={
            "text-primary question-icon " +
            (isOpen ? "question-icon-expanded" : "")
          }
        />
        <div className="question-label">{label}</div>
      </h5>
      <Collapse in={isOpen}>
        <div className="answer">{children}</div>
      </Collapse>
    </div>
  );
};
