import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import logo from "../../assets/images/logo.png";
import heroTopImg from "../../assets/images/hero-top-img.png";
import Link from "../Link";
import style from "./style.scss";

export default () => {
  return (
    <>
      <Navbar fixed="top" expand="lg">
        <img
          src={heroTopImg}
          className="img-fluid d-xl-block d-none"
          alt="image"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            maxWidth: "250px",
          }}
        />
        <Container>
          <Navbar.Brand href="#home">
            <img
              src={logo}
              className="logo img-fluid"
              alt="logo"
              style={{
                height: "55px",
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="me-5" id="basic-navbar-nav">
            <Nav>
              <Link to="/">Home</Link>
              <Link href="https://www.writerduet.com/blog">Blog</Link>
              <Link href="https://www.writerduet.com/help/">Help</Link>
              <Link to="/pricing">Pricing</Link>
              <Link href="/script/#_login">Login</Link>
            </Nav>
          </Navbar.Collapse>
          <Button
            variant="dark"
            className="d-lg-block d-none get-started-btn"
            style={{ position: "relative", zIndex: 10 }}
            href="/script/#_create_account"
          >
            Get started
          </Button>
        </Container>
      </Navbar>
    </>
  );
};
