import React from "react";
import { ChevronDown } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";

import variables from "./style.scss";

// change bullets into a list



export default (
    { title, colorName, coloredCaption, price, billingLine, bulletHeader, bullets, buttonLabel, buttonLink, tiltLeft, billingPeriod }
) => {

    let color = variables[colorName];
    let tiltClass = tiltLeft ? "bottom-card tilt-left" : "bottom-card tilt-right";


    return (
        <div className="pricing-card">
            <div className={tiltClass} style={{ backgroundColor: color }}></div>
            <div className="main-pricing-card">
                <div className="pricing-card-title" style={{ backgroundColor: color }}>
                    <div className="chevron-down"><ChevronDown className="pricing-chevron"></ChevronDown></div>
                    <div className="title-words">{title}</div>
                </div>
                <div className="card-contents">
                    <div>
                        <div className="colored-caption" style={{ color: color }}>{coloredCaption}</div>
                        <div className="price-block">
                            <div className="price-block-line-1">
                                <div className="card-dollar-sign">$</div>
                                <div className="card-price">{price}</div>
                                <div className="card-per-mo">/{billingPeriod || "mo"}</div>
                            </div>
                            <div className="price-block-line-2">{billingLine}</div>
                        </div>
                        <div className="bullets-section">
                            <div className="bullets-header">{bulletHeader}</div>
                            <ul className="bullets">{bullets.map((bullet, idx) => <li key={idx}><span>{bullet}</span></li>)}</ul>
                        </div>
                    </div>
                    <div className="card-button-container">
                        <Button className="price-card-button" href={buttonLink} style={{ backgroundColor: color }}>
                            <div className="button-label">{buttonLabel}</div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>

    );
};
