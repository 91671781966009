import React from "react";
import variables from "./style.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function drawCell(data, color) {
  if (typeof data == "boolean") {
    if (data) {
      return (
        <div className="feature-mark" style={{ backgroundColor: color }}></div>
      );
    } else {
      return "";
    }
  } else {
    let styl = { color };
    let txt = data;
    if (txt[0] === "-") {
      styl.color = variables["disabled-color"];
      txt = txt.slice(1);
    } else if (txt[0] === ">") {
      styl.paddingLeft = "2em";
      txt = txt.slice(1);
    }

    return <div style={styl}>{txt}</div>;
  }
}

export default ({
  header,
  headerColor,
  columnColors,
  rowData,
  extraStyle,
  megaHeaders = [],
}) => {
  const { height, width } = useWindowDimensions();
  const hColor = variables[headerColor];
  const featWidth = width > 900 ? "20%" : "52%";
  const markWidth = width > 900 ? "20%" : "12%";

  const cColor = columnColors.map((c) => {
    return variables[c];
  });

  return (
    <table className="pricing-table" style={extraStyle || {}}>
      <colgroup>
        <col width={featWidth} />
        <col width={markWidth} />
      </colgroup>
      <thead>
        {megaHeaders.length ? (
          <tr className="mega-headers">
            {megaHeaders.map((h, idx) => (
              <th key={idx} style={{ color: cColor[idx] }}>
                {h}
              </th>
            ))}
          </tr>
        ) : (
          <></>
        )}
        <tr>
          <th
            colSpan={columnColors.length}
            style={{ borderColor: hColor, color: hColor }}
          >
            {header}
          </th>
        </tr>
      </thead>
      <tbody>
        {rowData.map((cells, idx) => (
          <tr key={idx}>
            {cells.map((cell, cidx) => (
              <td key={cidx}>{drawCell(cell, cColor[cidx])}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
