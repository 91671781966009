import React from "react";
import Container from "react-bootstrap/Container";
import topImg from "../../assets/images/journey-top-img.png";
import topImgSmall from "../../assets/images/journey-top-img-small.png";
import bottomImg from "../../assets/images/journey-bottom-img.png";
import level1 from "../../assets/images/import_animated.gif";
import level1Top from "../../assets/images/journey-level-1-top.png";
import level1Bottom from "../../assets/images/journey-level-1-bottom.png";
import level2 from "../../assets/images/outline_animated.gif";
import level2Bottom from "../../assets/images/journey-level-2-bottom.png";
import level3Botoom from "../../assets/images/journey-level-3-bottom.png";
import level3 from "../../assets/images/write_animated.gif";
import level4 from "../../assets/images/revise_animated.gif";
import level4Bottom from "../../assets/images/journey-level-4-bottom.png";
import level5 from "../../assets/images/export_animated.gif";
import level5Bottom from "../../assets/images/journey-level-5-bottom.png";

import "./style.scss";

export default () => {
  return (
    <>
      <section
        className="journey-sec"
        style={{ color: "#fff", backgroundColor: "#162028" }}
      >
        <img
          src={topImg}
          className="img-fluid big-journey"
          alt="image"
          style={{ position: "absolute", top: 0 }}
        />
        <img
          src={topImgSmall}
          className="img-fluid small-journey"
          alt="image"
          style={{ position: "absolute", top: 0 }}
        />
        <Container>
          <h2 className="text-center font-weight-bold mb-md-5 mb-2 pb-2">
            آستمتع بكتابة عملك السينمائي من اول الفكرة حتي الآنتاج الفني
          </h2>

          {/* level 1 */}
          <div className="d-flex journey-step step-1 align-items-center justify-content-end mb-5 pb-md-5 pb-3">
            <div className="journey-step-left">
              <h3>آدخال جميع انواع النصوص</h3>
              <p>أينما بدأت، تابع من هنا.</p>
            </div>

            <div className="journey-step-wrap position-relative d-flex">
              <img src={level1Top} className="img-fluid top-img" alt="image" />

              <img src={level1} className="img-fluid main" alt="image" />

              <img
                src={level1Bottom}
                className="img-fluid bottom-img"
                alt="image"
              />
            </div>
          </div>

          {/* level 2 */}
          <div className="d-flex journey-step step-1 step-2 align-items-center justify-content-end mb-5 pb-md-5 pb-3">
            <div className="journey-step-wrap position-relative d-flex">
              <img src={level2} className="img-fluid main" alt="image" />

              <img
                src={level2Bottom}
                className="img-fluid bottom-img"
                alt="image"
              />
            </div>

            <div className="journey-step-left">
              <h3>كروت المشاهد بالترتيب </h3>
              <p>قصتك فريدة من نوعها.</p>
            </div>
          </div>

          {/* level 3 */}
          <div className="d-flex journey-step step-1 step-3 align-items-center justify-content-end mb-5 pb-md-5 pb-3">
            <div className="journey-step-left">
              <h3>آلف حكايتك </h3>
              <p>شخصياتك تحتاج إلى عقبات، أنت لا تحتاج إليها.</p>
            </div>

            <div className="journey-step-wrap position-relative d-flex">
              <img src={level3} className="img-fluid main" alt="image" />

              <img
                src={level3Botoom}
                className="img-fluid bottom-img"
                alt="image"
              />
            </div>
          </div>

          {/* level 4 */}
          <div className="d-flex journey-step step-1 step-2 step-4 align-items-center justify-content-end mb-5 pb-md-5 pb-3">
            <div className="journey-step-wrap position-relative d-flex">
              <img src={level4} className="img-fluid main" alt="image" lazylo />
              <img
                src={level4Bottom}
                className="img-fluid bottom-img"
                alt="image"
              />
            </div>

            <div className="journey-step-left">
              <h3>قم بالمراجهة و كتابة التعليقات الخاصة بك</h3>
              <p>كل إصدار خاص ، لذلك نقوم بحفظها جميعا.</p>
            </div>
          </div>

          {/* level 5 */}
          <div className="d-flex journey-step step-1 step-5 align-items-center justify-content-end mb-5 pb-md-5">
            <div className="journey-step-left">
              <h3>آحفظ فيلمك بآي صيغة تفضل</h3>
              <p>تم الانتهاء من مسودتك! قصتك ليست كذلك.</p>
            </div>

            <div className="journey-step-wrap position-relative d-flex">
              <img src={level5} className="img-fluid main" alt="image" />
              <img
                src={level5Bottom}
                className="img-fluid bottom-img"
                alt="image"
              />
            </div>
          </div>
        </Container>
        <img
          src={bottomImg}
          className="img-fluid d-xl-block d-none"
          alt="image"
          style={{ position: "absolute", bottom: 0 }}
        />
      </section>
    </>
  );
};
