import React from "react";
import Slide from "../../../components/Slide";
// import {
//   Jumbotron,
//   Button,
//   Container,
//   Row,
//   Col,
//   Image,
//   Badge,
//   Collapse,
// } from "react-bootstrap";

export default (props) => {
  return (
    <Slide>
      <h3 className="font-weight-bold text-center mb-5">
        Customer Support Specialist Contractor (Nights &amp; Weekends)
      </h3>

      {/* <Row> */}
      {/* <Col lg={6}>
          <Image src={typewriter} fluid />
        </Col> */}
      {/* <Col lg={6}> */}
      <div class="content">
        <div>
          At WriterDuet, every decision is made based on customer feedback, and
          supporting writers is our top priority! We are looking for a
          passionate communicator to join our awesome support team.
          <br />
          <br />
          The ideal candidate has experience communicating in a friendly and
          efficient manner, and in increasing customer loyalty even when faced
          with challenging or complex scenarios. We’ll be very excited about
          anybody with a passion for creativity, creative writing, and
          technology. And it's definitely a big plus if you're a screenwriter,
          filmmaker, or--most especially--a lover of WriterDuet!
          <br />
          <br />
          If you like learning a lot, prefer being challenged constantly, and
          enjoy helping others create better, then this is the job for you.
        </div>
      </div>
      {/* </Col> */}
      {/* </Row> */}
      <div>
        <br />
        Send your resume and cover letter to{" "}
        <a href="mailto:jobs@writerduet.com">jobs@writerduet.com</a>. Include a
        link to something you've created, plus tell us why you'd be a great fit!
        <br />
        <br />
        <em>
          We are a remote team, so this job is not dependent on location. That
          said, much of our team is currently based in Austin, and many of our{" "}
          <i>writers</i> are in Los Angeles, so we have a slight preference for
          those cities.
        </em>
      </div>
      <div>
        <hr />
        <div class="Slugline">Responsibilities:</div>
        <ul>
          <li>
            Work with users via messaging and phone to quickly identify
            problems, recommend solutions, and dramatically improve WriterDuet’s
            brand identity for that user
          </li>
          <li>
            Update self-help documents so users can more effectively solve
            problems themselves
          </li>
          <li>
            Escalate issues to relevant team members clearly and effectively,
            following up whenever necessary
          </li>
          <li>
            Test the product and isolate issues through reproduction and
            observation
          </li>
          <li>Identify common trends and underlying problems</li>
          <li>Act as the primary advocate for our user base</li>
          <li>
            Be creative and offer suggestions about features and product
            direction
          </li>
          <li>
            Learn as much as possible, tailored to your interests and skills
          </li>
        </ul>
        <div class="Slugline">Job Type:</div>
        <ul>
          <li>
            Initially, this is a part-time (20 hrs/week), contract with strong
            potential to work more hours based on mutual needs and growth
            trajectories.
          </li>
          <li>We are currently hiring for nights and weekends (CST).</li>
          <li>
            This will remain a contract role regardless of hours/week worked.
          </li>
        </ul>
        <div class="Slugline">Salary:</div>
        <ul>
          <li>
            We are offering $20/hr. There is a potential for adjustment that
            will coincide with your proven ability to support our customers.
          </li>
        </ul>
        <div class="Slugline">Required Qualifications:</div>
        <ul>
          <li>Background supporting customers or clients</li>
          <li>
            Knowledge of WriterDuet (you should at least check it out before
            applying)
          </li>
          <li>
            Experience with/passion for creative writing, scriptwriting, an/or
            filmmaking (there's likely room for content creation in this role)
          </li>
        </ul>
        <div class="Slugline">Nice-to-Haves:</div>
        <ul>
          <li>Experience with creative collaboration</li>
          <li>Experience with a technology company</li>
          <li>
            Interest in data/analytics, or at least a desire to measurably move
            meaningful metrics
          </li>
        </ul>
        <div class="Slugline">Our values:</div>
        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>Collaboration</span>. We offer
            and request assistance often to iterate more effectively, save time,
            and uncover insights.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Ownership</span>. We encourage
            team members to stand up and announce responsibilities by setting
            goals, supporting visions, and celebrating successes and failures.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Growth</span>. At WriterDuet,
            employees can find success in their daily tasks and expand skills in
            additional roles by controlling their responsibilities and what they
            learn.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Quality</span>. We measure
            decisions, make sustainable systems, and build innovative
            technology.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Enjoyment & Fulfillment</span>.
            The company embraces team collaboration, individual ownership,
            personal growth, and quality to provide an inspiring, happy, and
            creative workplace.
          </li>
        </ul>
        <div class="Slugline">
          <b>Our commitment:</b>
        </div>
        WriterDuet is committed to providing a friendly, safe and welcoming
        environment for all, regardless of gender, gender identity, gender
        expression, sexual orientation, disability, physical appearance, body
        size, age, race, ability, ethnicity, socioeconomic status, religion (or
        lack thereof), or veteran status. WriterDuet does not tolerate
        harassment or discrimination in any form. Above and beyond
        discrimination/harassment based on “protected categories,” WriterDuet
        also strives to prevent other, subtler forms of inappropriate behavior
        (e.g. stereotyping) from ever gaining a foothold in our office. Whether
        blatant or hidden, barriers to success have no place at WriterDuet.
      </div>
    </Slide>
  );
};
