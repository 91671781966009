import React from "react";
import logo from "./logo.svg";
import "./App.scss";
import { Router, Link, globalHistory } from "@reach/router";
import Header from "./components/Header";
import HeaderElsenario from "./components/Header/index_elsenario";
import Footer from "./components/Footer";
import FooterElsenario from "./components/Footer/index_elsenario";
import CookiesBanner from "./components/CookiesBanner";
import HomeNew from "./pages/HomeNew/index";
import HomeElsenario from "./pages/HomeNew/index_elsenario";
import PricingOriginal from "./pages/Pricing/index";
import PricingElsenario from "./pages/Pricing/index_elsenario";
//import PricingAlt from "./pages/Pricing/index_alt";
import Education from "./pages/Education";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Community from "./pages/Community";
import Careers from "./pages/Careers";
import Support from "./pages/Careers/Support";
import Devops from "./pages/Careers/Devops";
import Frontend from "./pages/Careers/Frontend";
//import { Experiment, Variant } from "react-optimize";

const pageAndComponentVersions = {
  "": {
    Home: HomeNew,
    Pricing: PricingOriginal,
    Header,
    Footer,
  },
  elsenario: {
    Home: HomeElsenario,
    Pricing: PricingElsenario,
    Header: HeaderElsenario,
    Footer: FooterElsenario,
  },
};
const pagesAndComponents = pageAndComponentVersions["elsenario"];

// const Home = (props) => {
//   return (
//     <Experiment id="zMKD0yXNQW2ZrZ8SKuDelA">
//       <Variant id="0">
//         <HomeOriginal default path="/" {...props} />
//       </Variant>
//       <Variant id="1">
//         <HomeAlt default path="/" {...props} />
//       </Variant>
//     </Experiment>
//   );
// };

// const Pricing = (props) => {
//   return (
//     <Experiment id="H3ClKEzES8uRfyk-RxMKHA">
//       <Variant id="0">
//         <PricingOriginal default path="/" {...props} />
//       </Variant>
//       <Variant id="1">
//         <PricingAlt default path="/" {...props} />
//       </Variant>
//     </Experiment>
//   );
// };

globalHistory.listen((options) => {
  if (window.sendPageview) {
    window.sendPageview();
  }
});

function App() {
  return (
    <div>
      <pagesAndComponents.Header />
      <Router primary={false}>
        <pagesAndComponents.Home default path="*" />
        <pagesAndComponents.Pricing path="pricing" />
        <Education path="education" />
        <Terms path="terms" />
        <Privacy path="privacy" />
        <Community path="community" />
        <Careers path="careers" />
        <Support path="careers/support" />
        <Devops path="careers/devops" />
        <Frontend path="careers/frontend" />
      </Router>

      <pagesAndComponents.Footer />

      {/* <CookiesBanner /> */}
    </div>
  );
}

export default App;
