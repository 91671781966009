import React from "react";
import SimpleSlider from "../OurFansSlider";
import textLove from "../../assets/images/text-love.png";

export default () => {
  return (
    <>
      <section className="ourfans-sec text-center">
        <h2 className="font-weight-bold">
          We <img src={textLove} className="img-fluid" alt="img"></img> Our Fans
        </h2>
        <SimpleSlider />
      </section>
    </>
  );
};
