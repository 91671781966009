import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import bottomBG from "../../assets/images/bottom-bg.png";
import Link from "../Link";
import style from "./style.scss";

export default () => {
  return (
    <>
      <section
        className="text-center bottom-sec"
        style={{
          color: "#fff",
          backgroundImage: `url(${bottomBG})`,
          backgroundColor: "#162028",
        }}
      >
        <Container className="mb-5 pb-5">
          <h2 className="fw-bold text-center mb-md-4 mb-2">
            {" "}
            Still have questions?
          </h2>
          <Button
            variant="primary"
            className="btn-xl mb-3"
            href="#"
            onClick={(e) => {
              e.preventDefault();

              // HELPSCOUT BEACON:
              if (!window.Beacon) {
                (function (e, t, n) {
                  function a() {
                    var e = t.getElementsByTagName("script")[0],
                      n = t.createElement("script");
                    n.type = "text/javascript";
                    n.async = !0;
                    n.src = "https://beacon-v2.helpscout.net";
                    e.parentNode.insertBefore(n, e);
                  }
                  if (
                    ((e.Beacon = n =
                      function (t, n, a) {
                        e.Beacon.readyQueue.push({
                          method: t,
                          options: n,
                          data: a,
                        });
                      }),
                    (n.readyQueue = []),
                    "complete" === t.readyState)
                  )
                    return a();
                  e.attachEvent
                    ? e.attachEvent("onload", a)
                    : e.addEventListener("load", a, !1);
                })(window, document, window.Beacon || function () {});

                window.Beacon("init", "e66c59f7-97c9-4a0f-a522-53baf4640893");
                window.Beacon("config", {
                  display: {
                    zIndex: 10000000,
                  },
                });
              }

              // END HELPSCOUT BEACON

              window.Beacon("open");
              window.Beacon("navigate", "/ask/");
            }}
          >
            We Have Answers
          </Button>
        </Container>

        <footer className="mt-5 pt-2">
          <Container>
            <Row>
              <Col md lg="6">
                © 2022 WriterDuet Inc.
                <a href="mailto:help@writerduet.com" className="mx-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-envelope-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                  </svg>{" "}
                  help@writerduet.com
                </a>
              </Col>

              <Col md lg="6">
                <ul className="d-flex align-items-center flex-wrap list-unstyled p-0 justify-content-md-end justify-content-center">
                  <li>
                    <a href="/pricing#faq" className="mx-2">
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a href="https://www.writerduet.com/help" className="mx-2">
                      Knowledgebase
                    </a>
                  </li>
                  <li>
                    <a href="https://www.writerduet.com/blog" className="mx-2">
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="/terms" className="mx-2">
                      Legal
                    </a>
                  </li>
                  <li>
                    <a href="/education" className="mx-2">
                      Education
                    </a>
                  </li>
                  <li>
                    <a href="/careers" className="mx-2">
                      Careers
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </footer>
      </section>
    </>
  );
};
