import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hero from "../../components/Hero";
import OurFans from "../../components/OurFans";
import Features from "../../components/Features";
import Journey from "../../components/Journey/index";
import WhyUs from "../../components/WhyUs/index";
import style from "./style.scss";

function App() {
  return (
    <div className="HomeNew">
      <Hero />
      <OurFans />
      <Features />
      <Journey />
      <WhyUs />
    </div>
  );
}

export default App;
