import React from "react";
import Slider from "react-slick";
import Card from "react-bootstrap/Card";
import personImg1 from "../../assets/images/person-img1.png";
import personImg2 from "../../assets/images/person-img2.png";
import personImg3 from "../../assets/images/person-img3.png";
import personImg4 from "../../assets/images/person-img4.png";
import glaucoDamasonImg from "../../assets/images/GlaucoDamas.jpeg";
// import edSolomonImg from "../../assets/images/edSolomon.png";
import mylesWardenImage from "../../assets/images/MylesWarden.jpeg";

import "./style.scss";

export default function SimpleSlider() {
  var settings = {
    // arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 10000,
    slidesToShow: 5,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="text-start">
      <div>
        <Card className="fans-card  m-3">
          <Card.Body>
            <div class="fans-testimonial">
              WriterDuet is the most intuitive, most user-friendly scriptwriting
              software I’ve ever used. And it’s truly amazing to be able to
              write and share screens with one, two, or many different people at
              once. ... I wish I’d had this earlier in my career.
            </div>
            <div className="d-flex mt-md-5 pt-md-2">
              <div className="me-3">
                {/*<img src={edSolomonImg} className="img-fluid" alt="img" />*/}
              </div>
              <div style={{ width: "100%" }}>
                <h5 className="mb-0">Ed Solomon</h5>
                <p>
                  Men in Black, Bill &amp; Ted, Now You See Me, No Sudden Move
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div>
        <Card className="fans-card  m-3">
          <Card.Body>
            <div class="fans-testimonial">
              I am profoundly impressed with @WriterDuet . Full-featured,
              modern, stable, ***mobile-friendly***. A fundamental tool for
              today's screenwriters. #WriterDuet is THE new industry standard.
              Five stars. No, wait: six stars. Ten. Twenty stars (enough?).
            </div>
            <div className="d-flex mt-md-5 pt-md-2">
              <div className="me-3">
                <img
                  src={glaucoDamasonImg}
                  className="img-fluid fan-image"
                  alt="img"
                ></img>
              </div>
              <div style={{ width: "calc(100% - 120px)" }}>
                <h5 className="mb-0">Glauco Damas</h5>
                <p>@GlaucoDamas</p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div>
        <Card className="fans-card  m-3">
          <Card.Body>
            <div class="fans-testimonial">
              WriterDuet combines instinct and user-friendliness for a unique
              and exhilarating way for writers to collaborate, whether in
              real-time or in non-synchronized work. ... It's a
              meticulously-created gem, and a must-have for collaborations.
            </div>
            <div className="d-flex mt-md-5 pt-md-2">
              <div className="me-3">
                {/* <img src={personImg3} className="img-fluid" alt="img"></img> */}
              </div>
              <div style={{ width: "100%" }}>
                <h5 className="mb-0">Jim Uhls</h5>
                <p>Fight Club, Jumper</p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div>
        <Card className="fans-card  m-3">
          <Card.Body>
            <div class="fans-testimonial">
              The fluidity between @WriterDuet across apps is amazing. From the
              cell phone app (via app or website), to any laptop, iPad, etc.
              Everything is in instantly in sync and there are zero issues with
              stability or losing pages.
            </div>
            <div className="d-flex mt-md-5 pt-md-2">
              <div className="me-3">
                <img
                  src={mylesWardenImage}
                  className="img-fluid fan-image"
                  alt="img"
                ></img>
              </div>
              <div style={{ width: "calc(100% - 120px)" }}>
                <h5 className="mb-0">Myles Warden</h5>
                <p>@ReallyMighty</p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div>
        <Card className="fans-card  m-3">
          <Card.Body>
            <div class="fans-testimonial">
              The app saves my whole thought process for me. On top of that,
              these guys are iterating the app so fast, they're running circles
              around the industry leader. WriterDuet definitely feels like the
              future.
            </div>
            <div className="d-flex mt-md-5 pt-md-2">
              <div className="me-3">
                {/*<img src={personImg1} className="img-fluid" alt="img"></img>*/}
              </div>
              <div style={{ width: "100%" }}>
                <h5 className="mb-0">Andy Bobrow</h5>
                <p>
                  Brooklyn Nine-Nine, Bless the Harts, Community, Last Man On
                  Earth
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div>
        <Card className="fans-card  m-3">
          <Card.Body>
            <div class="fans-testimonial">
              @WriterDuet Thank You for developing such a Rock Star product! One
              of the best decisions I made as a Playwright was to start using
              #WriterDuet as the vehicle to drive my passion through several
              devices no matter where I am!
            </div>
            <div className="d-flex mt-md-5 pt-md-2">
              <div className="me-3">
                <img src={personImg2} className="img-fluid" alt="img"></img>
              </div>
              <div style={{ width: "calc(100% - 120px)" }}>
                <h5 className="mb-0">Conrad A. Panganiban</h5>
                <p>@consplayspace</p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Slider>
  );
}
