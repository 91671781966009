import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import textLove from "../../assets/images/text-love.png";
import Card from "react-bootstrap/Card";
import writeanytimeanywhereImg from "../../assets/images/writeanytimeanywhere-icon.png";
import neverloseworkImg from "../../assets/images/neverlosework-icon.png";
import outlineImg from "../../assets/images/outline-icon.png";
import collaborateImg from "../../assets/images/collaborate-icon.png";
import writeofflineImg from "../../assets/images/writeoffline-icon.png";
import exportImg from "../../assets/images/export-icon.png";
import importImg from "../../assets/images/import-icon.png";
import getfeedbackImg from "../../assets/images/getfeedback-icon.png";
import readaloudImg from "../../assets/images/readaloud-icon.png";
import customizeyourworkspaceImg from "../../assets/images/customizeyourworkspace-icon.png";
import statsImg from "../../assets/images/stats-icon.png";
import templatesImg from "../../assets/images/templates-icon.png";

export default () => {
  return (
    <>
      <section className="sec-pad why-us-sec">
        <Container>
          <h2 className="font-weight-bold text-center mb-md-5 mb-2 pb-3">
            لماذا السيناريو ؟
          </h2>

          <Row>
            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={writeanytimeanywhereImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    آكتب في آي وقت و آي مكان من خلال المتصفح او التليفون المحمول
                  </Card.Title>
                  <Card.Text>
                    تتم مزامنة كل ما تكتبه أثناء الاتصال بالإنترنت مع السحابة.
                    تسجيل الدخول والعمل على أي جهاز كمبيوتر أو جهاز محمول.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={neverloseworkImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    كل كلمة في آمان، لا تحمل هم تخزين ملفاتك
                  </Card.Title>
                  <Card.Text>
                    يتم حفظ النسخ الاحتياطية باستمرار في التطبيق. قم بتعيين نسخ
                    احتياطية إضافية على جهازك ، البريد الإلكتروني ، Google Drive
                    ، Dropbox.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={writeofflineImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    آكتب آوفلاين، يمكنك الكتابة بدون الاتصال بشبك الآنتونت و سوف
                    يقوم السيناريو بحفظ ملفاتك و تخزينها بمجرد الآتصال مره آخري
                  </Card.Title>
                  <Card.Text>
                    لا يزال بإمكانك الكتابة ، حتى بدون الإنترنت. بمجرد اتصالك
                    بالإنترنت مرة أخرى ، تتم مزامنة التغييرات تلقائيًا.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={collaborateImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    العمل مع فريق الكتابة
                  </Card.Title>
                  <Card.Text>
                    اكتب مع العديد من المتعاونين وشاهد تغييرات الجميع على الفور.
                    الدردشة النصية والمرئية داخل التطبيق.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={outlineImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    رتب احداث فيلم في اي وقت
                  </Card.Title>
                  <Card.Text>
                    أعد ترتيب المشاهد أو تجميعها في تسلسلات باستخدام البطاقات.
                    قم بإجراء اتصالات مرئية مع خريطة العقل
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={getfeedbackImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    آحصل علي آراء فريقك في اسرع وقت عن طريق
                  </Card.Title>
                  <Card.Text>
                    إنشاء روابط للقراءة فقط لمشاركتها مع أي شخص. يمكن لضيوفك
                    القراءة والاستماع والتعليق ، كل ذلك في مكان واحد.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={importImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    تحميل كل آنواع الملفات
                  </Card.Title>
                  <Card.Text>
                    استيراد أي نوع ملف تقريبًا قم بتحميل ملف PDF ، ومسودة نهائية
                    ، و Word ، ونافورة ، وغيرها. يتم الاحتفاظ بالتنسيق الأصلي
                    لك.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={exportImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    حفظ كل آنواع الملفات
                  </Card.Title>
                  <Card.Text>
                  قم بتنزيل البرامج النصية بتنسيق PDF والمسودة النهائية وWord والنافورة والمزيد. دمج مستندات متعددة في ملف واحد.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={statsImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    تقرير و حالة كل مشروع تقرير و حالة كل مشروع
                  </Card.Title>
                  <Card.Text>
                    احصائيات وتقارير استكشف الإحصائيات لاكتساب نظرة ثاقبة حول
                    كتاباتك. إنشاء تقارير للتحضير للإنتاج.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={readaloudImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    آسمع كل ما كتبت
                  </Card.Title>
                  <Card.Text>
                  استمع إلى أصوات الكمبيوتر المميزة لكل شخصية، أو قم بدعوة الممثلين الصوتيين لسماع قصتك تنبض بالحياة.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={customizeyourworkspaceImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    تعديل واجهة البرنامج كما تري
                  </Card.Title>
                  <Card.Text>
                    تخصيص مساحة العمل الخاصة بك رتب أدواتك كيفما تشاء. اضبط
                    ألوان التطبيق أو قم بتشغيل الوضع الداكن لتهدئة عينيك.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={templatesImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    قوالب جاهزة لكل انواع المؤلفين
                  </Card.Title>
                  <Card.Text>
                    قوالب لجميع الكتاب اختر من بين الخيارات مثل Stageplay و
                    Comics و Podcast والمزيد. أو قم بعمل تنسيق خاص بك.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
