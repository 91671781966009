import React, { useState } from "react";
import {
  Jumbotron,
  Button,
  Container,
  Row,
  Col,
  Image,
  Badge,
  Collapse,
} from "react-bootstrap";
import Slide from "../../components/Slide";
import Toggle from "../../components/Toggle";
import Question from "../../components/Question";
import PriceCard from "../../components/PriceCard";

import { Pencil, CardList, CloudUpload } from "react-bootstrap-icons";
import IconCircle from "../../components/IconCircle";
import style from "./style.scss";
import { useResetScroll } from "../../utils";

export default (props) => {
  useResetScroll();

  return (
    <Slide>
      <p>
        <strong>Community Guidelines</strong>
      </p>
      <p>
        Use of this website signifies your acceptance of WriterDuet Inc.'s
        <a href="/terms"> terms of service agreement</a>,{" "}
        <a href="/privacy"> privacy policy</a>, and the community guidelines
        below.
      </p>
      <p>
        If you have questions about any of these agreements, you can contact us
        at help@writerduet.com.
      </p>
      <p>
        All registered and unregistered WRITERDUET INC. users agree to comply
        with the rules of conduct described on this page. If you do not agree,
        you should not give us access to any of your personal information or
        original content and should not use our Website, products, or services
        (collectively, our "Service") . If we alter any of these Guidelines,
        your use of WRITERDUET INC.’s services or Websites after we post those
        changes signifies your acceptance of these revisions.
      </p>
      <p>
        WRITERDUET INC. reserves the right to investigate or terminate your
        membership if you have misused the Service or have behaved in any way
        that we consider inappropriate, unlawful or illegal. If we terminate
        your registered account because of such behavior, any fees that you've
        paid to WRITERDUET INC. will be forfeited.
      </p>
      <p>
        <strong>COMMUNITY GUIDELINES: YOUR BEHAVIOR ON WRITERDUET INC.</strong>
      </p>
      <p>
        These Guidelines describe types of behavior that, even when otherwise
        legal, may result in loss of user privileges and forfeiture of any fees
        that you've paid us.
        <ol>
          <li>
            You will not post messages, screenplays, photographs, audio
            recordings, video clips, text ﬁles, or any other information or
            media, or use our Website or Service in any way that is fraudulent
            or otherwise unlawful; or that otherwise violates or infringes upon
            the rights of any party. This includes activities that involve
            copyright or trademark infringement, defamation, or violation of
            another party's right to privacy or publicity, or of other personal
            or property right.
          </li>
          <li>
            You will not use our Website or Service to distribute or upload
            malware, including viruses, Trojan horses, phishing attempts, or
            adware, or to do anything else that might cause harm to WRITERDUET
            INC., to its Service, hardware, software, or data, or to any other
            party's computer systems or data.
          </li>
          <li>
            You may not solicit or advertise to other WRITERDUET INC. users. You
            may not use any part of our Website or Service to distribute,
            promote, or otherwise publish advertising or solicit funds, goods,
            or services from any person or entity.
          </li>
          <li>
            You will not use WRITERDUET INC. to engage in any form of harassment
            or offensive behavior, such as posting scripts, messages, pictures,
            or recordings that contain libelous, slanderous, abusive, or
            defamatory statements or racist, pornographic, obscene, threatening,
            or offensive language.
          </li>
          <li>
            You will not modify, adapt, sublicense, translate, sell,
            reverse-engineer, decipher, decompile, or otherwise disassemble any
            component of our Website, software, products, or any other part of
            our Service, or cause or help others to do so.
          </li>
          <li>
            You will not impersonate any person or entity when using any
            WRITERDUET INC.'s Websites, products, or services.
          </li>
          <li>
            You may post links to WRITERDUET INC. web pages from third-party
            Websites, but will not otherwise frame, embed, link to, or mirror
            any part of the WRITERDUET INC. Web sites without our prior express
            written consent.
          </li>
          <li>
            You will not use metatags or any other software mechanisms or
            third-party services that refer to our Website or direct Internet
            users to or from our site for any purpose.
          </li>
          <li>
            You will not use any robot, spider, site search/retrieval
            application, search-optimization tools, adwords, or other manual or
            automatic device or process to retrieve, index, data mine, or
            otherwise reproduce or circumvent the navigational structure or
            presentation of WRITERDUET INC. or its contents.
          </li>
          <li>
            You will not forge headers or otherwise manipulate identiﬁers in
            order to disguise the origin of any information transmitted to,
            from, or through WRITERDUET INC.
          </li>
          <li>
            You will not remove any copyright, trademark, or other notices of
            legal or property rights posted on our Web site or embedded within
            the source code of our Website, Service, other software, or other
            product offerings.
          </li>
          <li>
            You will not interfere with or disrupt the normal operation of our
            Website or Service or participate in unauthorized access to our
            servers or networks.
          </li>
          <li>
            You will not state or imply, even through silence or omission, that
            any statements you make are endorsed by WRITERDUET INC. without our
            speciﬁc prior written consent.
          </li>
          <li>
            You may not post, distribute, or reproduce in any way any
            third-party copyrighted content, trademarks, or other proprietary
            information without obtaining the prior written consent of all legal
            owners of that content.
          </li>
          <li>
            The WRITERDUET INC. Website is for personal, individual use only.
            Any illegal or commercial use of this site not mentioned elsewhere
            in these Guidelines will result in immediate termination of your
            registered account and forfeiture of all fees.
          </li>
        </ol>
      </p>
    </Slide>
  );
};
