import React from "react";
import Slide from "../../components/Slide";
import {
  Jumbotron,
  Button,
  Container,
  Row,
  Col,
  Image,
  Badge,
  Collapse,
} from "react-bootstrap";
import students from "./students-collaborating.jpg"; // Tell webpack this JS file uses this image
import { useResetScroll } from "../../utils";
import "./style.scss";

export default (props) => {
  useResetScroll();

  return (
    <Slide className="pt-100">
      <div class="bee-page-container">
        <div class="bee-row bee-row-2 bee-mobile_hide">
          <div class="bee-row-content">
            <div class="bee-col bee-col-1 bee-col-w12">
              <div class="bee-block bee-block-2 bee-heading">
                <h2
                  style={{
                    color: "#1b1b1b",
                    direction: "ltr",
                    fontFamily: "'Open Sans'",
                    fontWeight: "bold",
                    letterSpacing: "normal",
                    textAlign: "center",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  <strong>Collaborate &amp; Educate Seamlessly</strong>
                </h2>
              </div>
              <div class="bee-block bee-block-3 bee-heading">
                <h1
                  style={{
                    color: "#1b1b1b",
                    direction: "ltr",
                    fontFamily: "'Open Sans'",
                    fontSize: 24,
                    fontWeight: 400,
                    letterSpacing: "normal",
                    lineHeight: "120%",
                    textAlign: "center",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  with the industry-standard software used by over 1 million
                  screenwriters.
                </h1>
              </div>
              <div class="bee-block bee-block-4 bee-spacer">
                <div class="spacer" style={{ height: 15 }}></div>
              </div>
            </div>
          </div>
        </div>
        <div class="bee-row bee-row-3 bee-mobile_hide">
          <div class="bee-row-content">
            <div class="bee-col bee-col-1 bee-col-w6">
              <div class="bee-block bee-block-1 bee-heading">
                <h1
                  style={{
                    color: "#4c4c4c",
                    direction: "ltr",
                    fontFamily: "'Open Sans'",
                    fontSize: 22,
                    fontWeight: "normal",
                    letterSpacing: "normal",
                    lineHeight: "200%",
                    textAlign: "left",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  ✓ Custom licenses for any budget
                  <br />
                  ✓ Real-time collaboration using any device
                  <br />
                  ✓ Students start writing in minutes
                  <br />
                  ✓ Easy in-app submission &amp; feedback process
                  <br />
                  ✓ Secure cloud-based backups
                  <br />
                </h1>
              </div>
            </div>
            <div class="bee-col bee-col-2 bee-col-w6">
              <div class="bee-block bee-block-1 bee-image">
                <img
                  alt="WD Classroom"
                  class="bee-center bee-fixedwidth"
                  src="https://firebasestorage.googleapis.com/v0/b/wd-server.appspot.com/o/websiteImages%2FWriterDuet_Education.png?alt=media&token=be0bc01f-e8c5-49ca-932b-c4d4cb61fc18"
                  style={{ maxWidth: 463 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="bee-row bee-row-4 bee-mobile_hide">
          <div class="bee-row-content">
            <div class="bee-col bee-col-1 bee-col-w6">
              <div class="bee-block bee-block-1 bee-button">
                <a
                  class="bee-button-content"
                  href="https://forms.monday.com/forms/3e7368d86c9db54ce8ff61ee17cc4b42?r=use1https://secure.helpscout.net/docs/5c6ebfc52c7d3a66e32ebf11/article/61fdc10539e5d05141b66b51/"
                  style={{
                    fontSize: 14,
                    lineHeight: "28px",
                    fontFamily: "'Open Sans'",
                    backgroundColor: "#46aaea",
                    borderBottom: "2px solid #46AAEA",
                    borderLeft: "2px solid #46AAEA",
                    borderRadius: 4,
                    borderRight: "2px solid #46AAEA",
                    borderTop: "2px solid #46AAEA",
                    color: "#ffffff",
                    maxWidth: "100%",
                    paddingBottom: 5,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 5,
                    width: "auto",
                    fontWeight: 400,
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "200%",
                      wordBreak: "break-word",
                    }}
                  >
                    <span style={{ fontSize: 20, lineHeight: "40px" }}>
                      <strong>GET A QUOTE</strong>
                    </span>
                  </span>
                </a>
              </div>
            </div>
            <div class="bee-col bee-col-2 bee-col-w6">
              <div class="bee-block bee-block-1 bee-button">
                <a
                  class="bee-button-content"
                  href="https://www.writerduet.com/article/351-writerduet-for-your-classroom"
                  style={{
                    fontSize: 14,
                    lineHeight: "28px",
                    fontFamily: "'Open Sans'",
                    backgroundColor: "#ffffff",
                    borderBottom: "2px solid #46AAEA",
                    borderLeft: "2px solid #46AAEA",
                    borderRadius: 4,
                    borderRight: "2px solid #46AAEA",
                    borderTop: "2px solid #46AAEA",
                    color: "#46aaea",
                    maxWidth: "100%",
                    paddingBottom: 5,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 5,
                    width: "auto",
                    fontWeight: 400,
                    display: "inline-block",
                  }}
                  target="_blank"
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "200%",
                      wordBreak: "break-word",
                    }}
                  >
                    <span style={{ fontSize: 20, lineHeight: "40px" }}>
                      <strong>LEARN MORE</strong>
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="bee-row bee-row-5 bee-desktop_hide">
          <div class="bee-row-content">
            <div class="bee-col bee-col-1 bee-col-w12">
              <div class="bee-block bee-block-1 bee-heading">
                <h2
                  style={{
                    color: "#1b1b1b",
                    direction: "ltr",
                    fontFamily: "'Open Sans'",
                    fontWeight: "bold",
                    letterSpacing: "normal",
                    textAlign: "center",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  <strong>Collaborate &amp; Educate Seamlessly</strong>
                </h2>
              </div>
              <div class="bee-block bee-block-2 bee-heading">
                <h1
                  style={{
                    color: "#1b1b1b",
                    direction: "ltr",
                    fontFamily: "'Open Sans'",
                    fontSize: 20,
                    fontWeight: 400,
                    letterSpacing: "normal",
                    lineHeight: "120%",
                    textAlign: "center",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  with the industry-standard software used by over 1 million
                  screenwriters.
                </h1>
              </div>
              <div class="bee-block bee-block-3 bee-heading">
                <h1
                  style={{
                    color: "#4c4c4c",
                    direction: "ltr",
                    fontFamily: "'Open Sans'",
                    fontSize: 16,
                    fontWeight: "normal",
                    letterSpacing: "normal",
                    lineHeight: "150%",
                    textAlign: "center",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  ✓ Affordable licenses based on your needs
                  <br />
                  ✓ Real-time collaboration using any device
                  <br />
                  ✓ Students start writing in minutes
                  <br />
                  ✓ Easy in-app submission &amp; feedback process
                  <br />
                  ✓ Secure cloud-based backups
                  <br />
                </h1>
              </div>
              <div class="bee-block bee-block-4 bee-button">
                <a
                  class="bee-button-content"
                  href="https://forms.monday.com/forms/3e7368d86c9db54ce8ff61ee17cc4b42?r=use1https://secure.helpscout.net/docs/5c6ebfc52c7d3a66e32ebf11/article/61fdc10539e5d05141b66b51/"
                  style={{
                    fontSize: 14,
                    lineHeight: "28px",
                    fontFamily: "'Open Sans'",
                    backgroundColor: "#46aaea",
                    borderBottom: "2px solid #46AAEA",
                    borderLeft: "2px solid #46AAEA",
                    borderRadius: 4,
                    borderRight: "2px solid #46AAEA",
                    borderTop: "2px solid #46AAEA",
                    color: "#ffffff",
                    maxWidth: "100%",
                    paddingBottom: 5,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 5,
                    width: "auto",
                    fontWeight: 400,
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "200%",
                      wordBreak: "break-word",
                    }}
                  >
                    <span style={{ fontSize: 16, lineHeight: "32px" }}>
                      <strong>GET A QUOTE</strong>
                    </span>
                  </span>
                </a>
              </div>
              <div class="bee-block bee-block-5 bee-button">
                <a
                  class="bee-button-content"
                  href="https://www.writerduet.com/article/351-writerduet-for-your-classroom"
                  style={{
                    fontSize: 14,
                    lineHeight: "28px",
                    fontFamily: "'Open Sans'",
                    backgroundColor: "#ffffff",
                    borderBottom: "2px solid #46AAEA",
                    borderLeft: "2px solid #46AAEA",
                    borderRadius: 4,
                    borderRight: "2px solid #46AAEA",
                    borderTop: "2px solid #46AAEA",
                    color: "#46aaea",
                    maxWidth: "100%",
                    paddingBottom: 5,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 5,
                    width: "auto",
                    fontWeight: 400,
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "200%",
                      wordBreak: "break-word",
                    }}
                  >
                    <span style={{ fontSize: 16, lineHeight: "32px" }}>
                      <strong>LEARN MORE</strong>
                    </span>
                  </span>
                </a>
              </div>
              <div class="bee-block bee-block-6 bee-image">
                <img
                  alt="WD Classroom"
                  class="bee-center bee-fixedwidth"
                  src="https://firebasestorage.googleapis.com/v0/b/wd-server.appspot.com/o/websiteImages%2FWriterDuet_Education_Mobile.png?alt=media&token=97a16e0e-d003-4a39-bb05-641ec1de5557"
                  style={{ maxWidth: 640 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="bee-row bee-row-6">
          <div class="bee-row-content">
            <div class="bee-col bee-col-1 bee-col-w12">
              <div class="bee-block bee-block-1 bee-text">
                <div
                  class="bee-text-content"
                  style={{
                    fontSize: 14,
                    lineHeight: "120%",
                    fontFamily: "'Open Sans'",
                    color: "#393d47",
                  }}
                >
                  <p
                    style={{
                      fontSize: 14,
                      lineHeight: "16px",
                      textAlign: "center",
                      marginBottom: 0,
                    }}
                  >
                    <span style={{ fontSize: 16, lineHeight: "19px" }}>
                      Individual students and educators receive
                      <strong> 50% off </strong>
                      any subscription.
                      <br />
                      <a
                        href="https://www.writerduet.com/article/133-educational-discount"
                        rel="noopener"
                        style={{
                          textDecoration: "underline",
                          color: "#46aaea",
                        }}
                        target="_blank"
                      >
                        Get an individual discount!
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bee-row bee-row-7">
          <div class="bee-row-content">
            <div class="bee-col bee-col-1 bee-col-w12">
              <div class="bee-block bee-block-1 bee-spacer">
                <div class="spacer" style={{ height: 60 }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};
