import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import textLove from "../../assets/images/text-love.png";
import Card from "react-bootstrap/Card";
import writeanytimeanywhereImg from "../../assets/images/writeanytimeanywhere-icon.png";
import neverloseworkImg from "../../assets/images/neverlosework-icon.png";
import outlineImg from "../../assets/images/outline-icon.png";
import collaborateImg from "../../assets/images/collaborate-icon.png";
import writeofflineImg from "../../assets/images/writeoffline-icon.png";
import exportImg from "../../assets/images/export-icon.png";
import importImg from "../../assets/images/import-icon.png";
import getfeedbackImg from "../../assets/images/getfeedback-icon.png";
import readaloudImg from "../../assets/images/readaloud-icon.png";
import customizeyourworkspaceImg from "../../assets/images/customizeyourworkspace-icon.png";
import statsImg from "../../assets/images/stats-icon.png";
import templatesImg from "../../assets/images/templates-icon.png";

export default () => {
  return (
    <>
      <section className="sec-pad why-us-sec">
        <Container>
          <h2 className="font-weight-bold text-center mb-md-5 mb-2 pb-3">
            {" "}
            <span style={{ color: "#4CA9ED" }}>Why</span> you'll{" "}
            <img src={textLove} className="img-fluid" alt="img"></img>{" "}
            WriterDuet
          </h2>

          <Row>
            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={writeanytimeanywhereImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    Write Anytime, Anywhere
                  </Card.Title>
                  <Card.Text>
                    Everything you write while online is synced to the cloud.
                    Login and work on any computer or mobile device.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={neverloseworkImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    Never Lose Work
                  </Card.Title>
                  <Card.Text>
                    Backups are saved constantly in the app. Set additional
                    backups to your device, email, Google Drive, Dropbox.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={writeofflineImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    Write Offline
                  </Card.Title>
                  <Card.Text>
                    You can still write, even without internet. Once you're back
                    online, changes are synced automatically.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={collaborateImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    Collaborate In Real-Time
                  </Card.Title>
                  <Card.Text>
                    Write with multiple collaborators and see everyone's changes
                    instantly. Text and video chat within the app.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={outlineImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    Outline Your Story
                  </Card.Title>
                  <Card.Text>
                    Rearrange scenes or group into sequences with Cards. Make
                    visual connections with Mind Map.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={getfeedbackImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    Get Feedback Fast
                  </Card.Title>
                  <Card.Text>
                    Create read-only links to share with anyone. Your guests can
                    read, listen, and comment, all in one place.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={importImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    Import Virtually Any File Type
                  </Card.Title>
                  <Card.Text>
                    Upload PDF, Final Draft, Word, fountain, and others. The
                    original formatting is maintained for you.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={exportImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    Export Virtually Any File Type
                  </Card.Title>
                  <Card.Text>
                    Download scripts as PDF, Final Draft, Word, fountain, and
                    more. Combine multiple docs into a single file.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={statsImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    Stats And Reports
                  </Card.Title>
                  <Card.Text>
                    Explore statistics to gain insight about your writing.
                    Generate reports to prepare for production.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={readaloudImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    Listen To Your Script Out Loud
                  </Card.Title>
                  <Card.Text>
                    Listen to distinct computer voices for each character, or
                    invite voice actors to hear your story come to life.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={customizeyourworkspaceImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    Customize Your Workspace
                  </Card.Title>
                  <Card.Text>
                    Arrange your tools however you like them. Adjust the app's
                    colors or turn on Dark Mode to ease your eyes.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={templatesImg} />
                <Card.Body>
                  <Card.Title className="mb-md-3 mb-1">
                    Templates For All Writers
                  </Card.Title>
                  <Card.Text>
                    Choose from options like Stageplay, Comics, Podcast, and
                    more. Or make your own with custom formatting.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
