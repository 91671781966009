import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import textUnderline from "../../assets/images/text-underline.png";
import heroImg from "../../assets/images/hero-img.png";

export default () => {
  return (
    <div class="hero-section-wrap position-relative">
      <Container className="text-center">
        <div className="hero-sec">
          <h1>افضل برنامج لكتابة الافلام باللغة العربية</h1>
          <div className="text-end">
            <img src={textUnderline} className="img-fluid me-5" alt="img"></img>
          </div>

          <h2 className="mt-md-5 mt-2 mb-md-5 mb-2">
            التخطيط والكتابة والمشاركة مع البرامج المتوافقة مع معايير الصناعة
            المستخدمة من قبل أكثر من مليون كاتب سيناريو وبرامج تلفزيونية وأفلام
            رائجة
          </h2>
          <Button
            variant="primary"
            className="btn-xl mb-3"
            href="/script/#_create_account"
          >
            آبدآ الكتابة الآ
          </Button>
          <p>
            ٣مشاريع مجانية ، لا توجد علامات مائية أو حدود للصفحات ، لا توجد
            بطاقة ائتمان مطلوبة
          </p>
        </div>
        <img
          src={heroImg}
          className="img-fluid hero-main-img mt-5"
          alt="img"
        ></img>
      </Container>
    </div>
  );
};
