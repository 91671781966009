import React, { useState } from "react";
import Slide from "../../components/Slide";
import { useResetScroll } from "../../utils";

export default (props) => {
  useResetScroll();

  return (
    <Slide className="privacy">
      <p>
        Use of this website signifies your acceptance of WriterDuet Inc.'s
        <a href="/terms"> terms of service agreement</a>,
        <a href="/community"> community guidelines</a>, and the privacy policy
        below.
      </p>
      <p>
        If you have questions about any of these agreements, you can contact us
        at help@writerduet.com.
      </p>
      <h2>Privacy Policy</h2>
      <p>Effective date: October 23, 2022</p>
      <p>
        WRITERDUET INC. ("us", "we", or "our") includes the writerduet.com,
        writersolo.com, flexdocs.com, readthrough.com, and elsenario.com
        websites, the WriterDuet, WriterSolo, ReadThrough, and Elsenario mobile,
        browser, and desktop applications, and any other sites, applications, or
        software offered by WriterDuet Inc. (together considered the "Service").
      </p>
      <p>
        This page informs you of our policies regarding the collection, use, and
        disclosure of personal data when you use our Service and the choices you
        have associated with that data.
      </p>
      <p>
        We use your data to provide and improve the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this policy. Unless otherwise defined in this Privacy
        Policy, terms used in this Privacy Policy have the same meanings as in
        our Terms and Conditions.
      </p>
      <h3>Definitions</h3>
      <h4>Personal Data</h4>
      <p>
        Personal Data means data about a living individual who can be identified
        from those data (or from those and other information either in our
        possession or likely to come into our possession).
      </p>
      <h4>Usage Data</h4>
      <p>
        Usage Data is data collected automatically. It is either generated by
        the use of the Service or from the Service infrastructure itself (for
        example, we may collect data on the duration of a page visit).
      </p>
      <h4>Cookies</h4>
      <p>Cookies are small pieces of data stored on a User’s device.</p>
      <h4>Data Controller</h4>
      <p>
        {" "}
        A Data Controller is a person who (either alone or jointly or in common
        with other persons) determines the purposes for which and the manner in
        which any personal data are, or are to be, processed.
      </p>
      <p>
        For the purpose of this Privacy Policy, we are a Data Controller of your
        data.
      </p>
      <h4>Data Processor (or Service Providers)</h4>
      <p>
        A Data Processor (or Service Provider) is any person (other than an
        employee of the Data Controller) who processes the data on behalf of the
        Data Controller.
      </p>
      <p>
        We may use the services of various Service Providers in order to process
        your data more effectively.
      </p>
      <h4>Data Subject</h4>
      <p>
        A Data Subject is any living individual who is the subject of Personal
        Data.
      </p>
      <h4>User</h4>
      <p>
        The User is the individual using our Service. The User corresponds to
        the Data Subject, who is the subject of Personal Data.
      </p>
      <h3>Information Collection And Use</h3>
      <p>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </p>
      <h4>Types of Data Collected</h4>
      <h5>Personal Data</h5>
      <p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you ("Personal Data"). Personally identifiable information may
        include, but is not limited to:
      </p>
      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Cookies and Usage Data</li>
        <li>Phone number</li>
        <li>Records and copies of your correspondence, if you contact us</li>
        <li>
          Details of transactions you carry out through our website or Services
          and of the fulfilment of your orders. You may be required to provide
          financial information before placing an order through our Website or
          when ordering the Services.
        </li>
      </ul>
      <p>
        We will not use your Personal Data to contact you with newsletters,
        marketing or promotional materials and other information unless you
        OPT-IN when you register to the service. You may also opt out of
        receiving any, or all, of these communications from us by following the
        unsubscribe link in any email we send, or by opting out in the Account
        setting.
      </p>
      <h5>Usage Data</h5>
      <p>
        We may also collect information that your browser sends whenever you
        visit our Service or when you access the Service via mobile device
        ("Usage Data").
      </p>
      <p>
        This Usage Data may include information such as your computer's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that you visit, the time and date of your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        When you access the Service via mobile device, this Usage Data may
        include information such as the type of mobile device you use, your
        mobile device unique ID, the IP address of your mobile device, your
        mobile operating system, the type of mobile Internet browser you use,
        unique device identifiers and other diagnostic data.
      </p>
      <h5>Tracking &amp; Cookies Data</h5>
      <p>
        We use cookies and similar tracking technologies to track the activity
        on our Service and hold certain information.
      </p>
      <p>
        Cookies are files with a small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. Tracking technologies also used are
        beacons, tags, and scripts to collect and track information and to
        improve and analyze our Service.
      </p>
      <p>
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
      </p>
      <p>Examples of Cookies we use:</p>
      <ul>
        <li>
          <strong>Session Cookies.</strong> We use Session Cookies to operate
          our Service.
        </li>
        <li>
          <strong>Preference Cookies.</strong> We use Preference Cookies to
          remember your preferences and various settings.
        </li>
        <li>
          <strong>Security Cookies.</strong> We use Security Cookies for
          security purposes.
        </li>
      </ul>
      <h3>Use of Data</h3>
      <p>WRITERDUET INC. uses the collected data for various purposes:</p>
      <ul>
        <li>To provide and maintain our Service</li>
        <li>To notify you about changes to our Service</li>
        <li>
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </li>
        <li>To provide customer support</li>
        <li>
          To contact you about billing, account management or other
          administrative matters
        </li>
        <li>To fulfill any other purpose for which you provide it</li>
        <li>
          To gather analysis or valuable information so that we can improve our
          Service
        </li>
        <li>To monitor the usage of our Service</li>
        <li>To detect, prevent and address technical issues</li>
        <li>
          To provide you with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless you
          have opted not to receive such information
        </li>
      </ul>
      <p>We will never sell your Personal Data to any third party.</p>
      <h3>Retention of Data</h3>
      <p>
        WRITERDUET INC. will retain your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies. When it is no longer necessary to retain
        your Personal Data, we will delete or anonymize it.
      </p>
      <p>
        WRITERDUET INC. will also retain Usage Data for internal analysis
        purposes. Usage Data is generally retained for a shorter period of time,
        except when this data is used to strengthen the security or to improve
        the functionality of our Service, or we are legally obligated to retain
        this data for longer time periods.
      </p>
      <h3>Transfer Of Data</h3>
      <p>
        Your information, including Personal Data, may be held at our offices
        and those of our third party agencies, service providers,
        representatives and agents as described below in “Service Providers”,
        and may be transferred to — and maintained on — computers located
        outside of your state, province, country or other governmental
        jurisdiction where the data protection laws may differ from those in
        your jurisdiction.
      </p>
      <p>
        If you are located outside of the United States and choose to provide
        information to us, please note that we transfer the data, including
        Personal Data, to the United States and process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>
      <p>
        WRITERDUET INC. will take all steps reasonably necessary to ensure that
        your data is treated securely and in accordance with this Privacy
        Policy. No transfer of your Personal Data will take place to an
        organization or a country unless there are adequate controls in place
        including the security of your data and other personal information. For
        more information, see below “Transferring your Personal Information Out
        of the EEA” and “Your Rights Under the GDPR”.
      </p>
      <h3>Transferring Your Personal Data Out of the EEA.</h3>
      <p>
        To deliver services to you, it is sometimes necessary for us to share
        your personal information outside the European Economic Area (EEA),
        e.g.:
      </p>
      <ul>
        <li>With our offices outside the EEA;</li>
        <li>With your and our service providers located outside the EEA;</li>
        <li>If you are based outside the EEA; or</li>
        <li>
          Where there is an international dimension to the services we are
          providing to you.
        </li>
      </ul>
      <p>
        These transfers are subject to special rules under European and UK data
        protection law.
      </p>
      <p>
        Non-EEA countries do not have the same data protection laws as the
        United Kingdom and EEA. We will, however, ensure the transfer complies
        with data protection law and all personal information will be secure.
        Our standard practice is to use standard data protection contract
        clauses that have been approved by the European Commission. To obtain a
        copy of those clauses, visit the European Commission website at
        https://ec.europa.eu/ and search for "standard contractual clauses".
      </p>
      <p>
        If you would like further information, please contact us (see “How To
        Contact Us” below).
      </p>
      <h3>Your Rights Under the General Data Protection Regulation (GDPR).</h3>
      <p>
        The GDPR gives people under its protection, including people in the EEA
        and the United Kingdom, certain rights with respect to their personal
        data collected by us. Accordingly, WRITERDUET INC. recognizes and will
        comply with GDPR and those rights, except as limited by applicable law.
        The rights under GDPR include:
      </p>
      <ul>
        <li>
          <i>Right of Access.</i> This includes your right to access the
          personal data we gather about you, and your right to obtain
          information about the sharing, storage, security, and processing of
          that data.
        </li>
        <li>
          <i>Right to Correction.</i> This is your right to request correction
          of your personal data.
        </li>
        <li>
          <i>Right to Erasure.</i> This is your right to request, subject to
          certain limitations under applicable law, that your personal data be
          erased from our possession (also known as the “Right to be
          forgotten”). However, if applicable law requires us to comply with
          your request to delete your data, fulfillment of your request may
          prevent you from using the Service and may result in closing your
          account.
        </li>
        <li>
          <i>Right to Complain.</i> You have the right to make a complaint
          regarding our handling of your personal data with the appropriate
          supervisory authority.
        </li>
        <li>
          <i>Right to Restrict Processing.</i> This is your right to request
          restriction of how and why your personal data is used or processed.
        </li>
        <li>
          <i>Right to Object.</i>
          This is your right, in certain situations, to object to how or why
          your personal data is processed.
        </li>
        <li>
          <i>Right to Portability.</i> This is your right to receive the
          personal data we have about you and the right to transmit it to
          another party.
        </li>
        <li>
          <i>Right to not be subject to Automated Decision-Making.</i> This is
          your right to object and prevent any decision that could have a legal,
          or similarly significant, effect on you from being made solely based
          on automated processes. This right is limited, however, if the
          decision is necessary for performance of any contract between you and
          us, is allowed by applicable law, or is based on your explicit
          consent.
        </li>
      </ul>
      <p>
        For further information on each of those rights, including the
        circumstances in which they apply, see the guidance from the UK
        Information Commissioner's Office (ICO) on individual rights under the
        General Data Protection Regulation.
      </p>
      <h3>Disclosure Of Data</h3>
      <h4>General</h4>
      <p>
        WRITERDUET INC. may disclose aggregated information about our users, and
        information that does not identify any individual, without restriction.
      </p>
      <p>
        WRITERDUET INC. may disclose your Personal Data and other information
        that we collect, or you provide, as described in this Privacy Policy:
      </p>
      <ul>
        <li>To our subsidiaries and affiliates;</li>
        <li>
          To contractors, service providers, and other third parties we use to
          support our business and who are bound by contractual obligations to
          keep Personal Data confidential and use it only for the purposes for
          which we disclose it to them;
        </li>
        <li>To fulfill the purpose for which you provide it;</li>
        <li>
          For any other purpose disclosed by us when you provide the
          information;
        </li>
        <li>With your consent</li>
      </ul>
      <h4>Business Transaction</h4>
      <p>
        If WRITERDUET INC. is involved in a merger, acquisition or asset sale,
        your Personal Data may be transferred. We will provide notice before
        your Personal Data is transferred and becomes subject to a different
        Privacy Policy.
      </p>
      <h4>Legal Requirements</h4>
      <p>
        WRITERDUET INC. may disclose your Personal Data in the good faith belief
        that such action is necessary to:
      </p>
      <ul>
        <li>Comply with a legal obligation</li>
        <li>If necessary, for billing and collection purposes</li>
        <li>Protect and defend the rights or property of WRITERDUET INC.</li>
        <li>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          Protect the personal safety of users of the Service or the public
        </li>
        <li>Protect against legal liability</li>
      </ul>
      <h3>Security Of Data</h3>
      <p>
        {" "}
        We take appropriate precautions to protect our users’ personally
        identifying information. Your account data and content are encrypted
        in-transit and at rest on cloud servers.{" "}
      </p>
      <p>
        {" "}
        Because all security measures are subject to circumvention, we cannot
        unequivocally guarantee the effectiveness of our security measures or
        our ability to prevent unauthorized third parties from unlawfully
        obtaining information that you provide to us. In particular, because
        email is an inherently insecure medium, you shouldn't send private
        communications or sensitive data, such as credit card numbers, to us by
        email. If you have more detailed questions about our security, please
        contact us.{" "}
      </p>
      <h3>Your Rights</h3>
      <p>
        WRITERDUET INC. aims to take reasonable steps to allow you to correct,
        amend, delete, or limit the use of your Personal Data.
      </p>
      <p>
        You can update the following type of Personal Data directly within your
        account settings section (under Help > Account):
        <ul>
          <li>First name and Last name</li>
          <li>Email address</li>
          <li>Phone number</li>
        </ul>
        If you are unable to change your Personal Data, please contact us to
        make the required changes.
      </p>
      <p>
        If you wish to be informed what Personal Data we hold about you and if
        you want it to be removed from our systems, please contact us.
      </p>
      <p>You also have the right:</p>
      <ul>
        <li>
          To access and receive a copy of the Personal Data we hold about you
        </li>
        <li>To rectify any Personal Data held about you that is inaccurate</li>
        <li>To request the deletion of Personal Data held about you</li>
      </ul>
      <p>
        You have the right to data portability for the information you provide
        to WRITERDUET INC. You can request to obtain a copy of your Personal
        Data in a commonly used electronic format so that you can manage and
        move it.
      </p>
      <p>
        Please note that we may ask you to verify your identity before
        responding to such requests.
      </p>
      <h3>Service Providers</h3>
      <p>
        We may employ third party companies and individuals to facilitate our
        Service ("Service Providers"), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our
        Service is used.
      </p>
      <p>
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>
      <h4>Analytics and Engagement</h4>
      <p>
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
      </p>
      <ul>
        <li>
          <p>
            <strong>Google Analytics</strong>
          </p>
          <p>
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualize and personalize the ads of its own advertising
            network.
          </p>
          <p>
            You can opt-out of having made your activity on the Service
            available to Google Analytics by installing the Google Analytics
            opt-out browser add-on. The add-on prevents the Google Analytics
            JavaScript (ga.js, analytics.js, and dc.js) from sharing information
            with Google Analytics about visits activity.
          </p>
          <p>
            For more information on the privacy practices of Google, please
            visit the Google Privacy &amp; Terms web page:{" "}
            <a href="http://www.google.com/intl/en/policies/privacy/">
              http://www.google.com/intl/en/policies/privacy/
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Hubspot</strong>
          </p>
          <p>
            For information on the privacy practices of HubSpot, please visit
            the HubSpot Privacy Policy web page:{" "}
            <a href="https://legal.hubspot.com/privacy-policy">
              https://legal.hubspot.com/privacy-policy
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Mixpanel</strong>
          </p>
          <p>
            For more information on the privacy practices of Mixpanel, please
            visit the Mixpanel Privacy Policy web page:{" "}
            <a href="https://mixpanel.com/legal/privacy-policy/">
              https://mixpanel.com/legal/privacy-policy/
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Appcues</strong>
          </p>
          <p>
            For more information on the privacy practices of Appcues, please
            visit the Appcues Privacy Policy web page:{" "}
            <a href="https://www.appcues.com/privacy">
              https://www.appcues.com/privacy
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>OneSignal</strong>
          </p>
          <p>
            For more information on the privacy practices of OneSignal, please
            visit the OneSignal Privacy Policy web page:{" "}
            <a href="https://onesignal.com/privacy">
              https://onesignal.com/privacy
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Sentry</strong>
          </p>
          <p>
            For more information on the privacy practices of Sentry, please
            visit the Sentry Privacy Policy web page:{" "}
            <a href="https://sentry.io/privacy/">https://sentry.io/privacy/</a>
          </p>
        </li>
      </ul>
      <h4>Authentication</h4>
      <p>
        We may use third-party Service Providers for Authentication in our
        Service. We may request access to your First Name, Last Name, Email, and
        Phone Number. Their use will be strictly limited to the pratices
        described in the Personal Data subsection. For more information on how
        we store this information, refer to the Security of Data section
      </p>
      <ul>
        <li>
          <p>
            <strong>Firebase</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.firebase.com/terms/privacy-policy.html">
              https://www.firebase.com/terms/privacy-policy.html
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Google OAuth Service</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="http://www.google.com/intl/en/policies/privacy/">
              http://www.google.com/intl/en/policies/privacy/
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Facebook OAuth Service</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.facebook.com/full_data_use_policy">
              https://www.facebook.com/full_data_use_policy
            </a>
          </p>
        </li>
      </ul>
      <h4>Cloud Storage</h4>
      <p>
        We may use third-party Service Providers for Cloud Storage and backups.
        We use Cloud Storage APIs to store your documents on the WriterDuet
        cloud, as well as open and store documents directly from your personal
        storage accounts with the following cloud providers. We only open or
        store files with your permission or for the specific service being
        provided. Once files are written on or imported into WriterDuet, they
        become part of your WriterDuet account. Files written or imported when
        using our offline-only WriterSolo application are not saved on the
        WriterDuet cloud.
      </p>
      <p>For Cloud Storage we work with:</p>
      <ul>
        <li>
          <p>
            <strong>Firebase</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.firebase.com/terms/privacy-policy.html">
              https://www.firebase.com/terms/privacy-policy.html
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>AWS</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://aws.amazon.com/privacy/ ">
              https://aws.amazon.com/privacy/
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Google Drive</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://policies.google.com/privacy?hl=en&gl=us">
              https://policies.google.com/privacy?hl=en&gl=us
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Dropbox</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.dropbox.com/terms#privacy">
              https://www.dropbox.com/terms#privacy
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>iCloud</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.apple.com/legal/privacy/en-ww/">
              https://www.apple.com/legal/privacy/en-ww/
            </a>
          </p>
        </li>
      </ul>
      <h4>Payments</h4>
      <p>
        We may provide paid products and/or services within the Service. In that
        case, we use third-party services for payment processing and payment
        data services (e.g. payment processors).
      </p>
      <p>
        We will not store or collect your payment card details. That information
        is provided directly to our third-party payment processors whose use of
        your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </p>
      <p>The payment processors and payment data services we work with are:</p>
      <ul>
        <li>
          <p>
            <strong>Stripe</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://stripe.com/us/privacy">
              https://stripe.com/us/privacy
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Paddle</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://paddle.com/privacy">https://paddle.com/privacy</a>
          </p>
        </li>
        <li>
          <p>
            <strong>PayPal</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.paypal.com/myaccount/privacy/privacyhub">
              https://www.paypal.com/myaccount/privacy/privacyhub
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>ProfitWell</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.profitwell.com/privacy-policy">
              https://www.profitwell.com/privacy-policy
            </a>
          </p>
        </li>
      </ul>
      <h4>
        Text-to-Speech, Translation, Transcription, Search, Spellcheck, Video
        Chst, Sentiment Analysis, and Related Tools
      </h4>
      <p>
        We may share documents and related content with service-provider
        Partners in order to provide document conversion, performance, analysis,
        search, communication, and suggestions
      </p>
      <p>We work with:</p>
      <ul>
        <li>
          <p>
            <strong>Microsoft</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.microsoft.com/en-us/TrustCenter/Privacy/default.aspx">
              https://www.microsoft.com/en-us/TrustCenter/Privacy/default.aspx
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Google</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="http://www.google.com/intl/en/policies/privacy/">
              http://www.google.com/intl/en/policies/privacy/
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>AWS</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://aws.amazon.com/privacy/">
              https://aws.amazon.com/privacy/
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>IBM</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.ibm.com/privacy">
              https://www.ibm.com/privacy
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Rev</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.rev.com/about/privacy">
              https://www.rev.com/about/privacy
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Typesense</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://cloud.typesense.org/legal/privacy">
              https://cloud.typesense.org/legal/privacy
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Oracle</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.oracle.com/legal/privacy/">
              https://www.oracle.com/legal/privacy/
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Twilio</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.twilio.com/legal/privacy/">
              https://www.twilio.com/legal/privacy/
            </a>
          </p>
        </li>
      </ul>
      <h4>Customer Support</h4>
      <p>
        For better customer support, we rely on third party service to provide
        either in app or email assistance. We may share your information with
        them to improve the quality of the Service
      </p>
      <p>The customer support services we work with are:</p>
      <ul>
        <li>
          <p>
            <strong>Monday.com</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://monday.com/l/privacy/privacy-policy/">
              https://monday.com/l/privacy/privacy-policy/
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>HelpScout</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.helpscout.com/company/legal/privacy/">
              https://www.helpscout.com/company/legal/privacy/
            </a>
          </p>
        </li>
      </ul>
      <h4>Emailing and Notifications</h4>
      <p>
        With your consent, we will occasionally email you or send notifications
        via app, text, or web browser to inform you of updates on the Service
        and promotional content. We may share your information with third-party
        emailing and notification services to improve the quality of the
        Service. We may also send you notifications when certain events occur on
        your account or documents.
      </p>
      <p>The emailing and notification services we work with are:</p>
      <ul>
        <li>
          <p>
            <strong>Mailgun</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.mailgun.com/privacy-policy">
              https://www.mailgun.com/privacy-policy
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Mixpanel</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://mixpanel.com/legal/privacy-policy/">
              https://mixpanel.com/legal/privacy-policy/
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Twilio</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.twilio.com/legal/privacy/">
              https://www.twilio.com/legal/privacy/
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>OneSignal</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://onesignal.com/privacy">
              https://onesignal.com/privacy
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Expo</strong>
          </p>
          <p>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://expo.dev/privacy">https://expo.dev/privacy</a>
          </p>
        </li>
      </ul>
      <h3>Links To Other Sites</h3>
      <p>
        Our Service may contain links to other sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        of every site you visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <h3>Children's Privacy</h3>
      <p>
        Our Service does not address anyone under the age of 13 ("Children").
      </p>
      <p>
        We do not knowingly collect personally identifiable information from
        anyone under the age of 13. If you are a parent or guardian and you are
        aware that your Children has provided us with Personal Data, please
        contact us. If we become aware that we have collected Personal Data from
        a child without verification of parental consent, we take steps to
        remove that information from our servers.
      </p>
      <h3>How to File a GDPR Complaint.</h3>
      <p>
        We hope that we can resolve any query or concern you raise about our use
        of your data. Please contact us at help@writerduet.com to inform us of
        any concerns. The same email address may be used to contact WRITERDUET
        INC.’s Data Protection Officer. We will seek to deal with your request
        without undue delay, and in any event in accordance with the
        requirements of the GDPR. Please note that we may keep a record of your
        communcations to help us resolve any query or concerns which you raise.
      </p>
      <p>
        The General Data Protection Regulation also gives you right to lodge a
        complaint with a supervisory authority, in the European Union (or
        European Economic Area) state where you work, normally live, or where
        any alleged infringement of data protection laws occurred.
      </p>
      <h3>Changes To This Privacy Policy</h3>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and will update the "effective
        date" at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us:
      </p>
      <ul>
        <li>By email: help@writerduet.com</li>
        <li>By regular mail: 5201 Encinitas Lane, Austin, TX 78749</li>
      </ul>
    </Slide>
  );
};
