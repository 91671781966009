import React from "react";
import { Location, useLocation, useNavigate } from "@reach/router";

export function useResetScroll() {
  const navigate = useNavigate();
  const { href, state } = useLocation();

  const updateState = React.useCallback(() => {
    navigate(href, {
      state: { ...state, scrolled: true },
      replace: true,
    }).then(() => window.scrollTo(0, 0));
  }, [href, state, navigate]);

  React.useLayoutEffect(() => {
    if (!state?.scrolled) {
      updateState();
    }
  }, [state, updateState]);
}
