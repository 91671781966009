import React, { useCallback, useState } from "react";
import {
  Button,
  Navbar,
  NavDropdown,
  Nav,
  Container,
  Image,
  Row,
  Col,
} from "react-bootstrap";
import Link from "../Link";
import style from "./style.scss";

const buttonStyle = {
  fontFamily: "Roboto,Helvetica,Arial,sans-serif",
  fontSize: 14,
  minWidth: 64,
  verticalAlign: "middle",
  boxShadow:
    "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12)",
  color: "white",
  textAlign: "center",
  margin: "0px auto",
  width: "120px",
  height: "40px",
  borderRadius: "2px",
  fontSize: "14px",
  fontWeight: 450,
  display: "block",
  border: "none",
  marginTop: 10,
  marginBottom: 10,
};

export default (props) => {
  const [showBanner, setShowBanner] = useState(
    (localStorage.getItem("cookieAgreeDate") || 0) <
      Date.now() - 5 * 30 * 24 * 60 * 60 * 1000
  );

  const agreeToU = useCallback(() => {
    localStorage.setItem("cookieAgreeDate", Date.now());
    setShowBanner(false);
  }, []);

  return (
    showBanner && (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          background: "white",
          borderTop: "1px solid #EEE",
          fontSize: 12,
          zIndex: 10000,
        }}
      >
        <Container className="pt-4 pb-4">
          <Row>
            <Col
              // xs={{ order: 2 }}

              className={"align-self-center"}
              sm={10}
            >
              We use cookies on our websites to deliver our online services.
              Details about how we use cookies and how you may disable them are
              set out in our <a href="/privacy">Privacy Policy</a>. By using
              this website you agree to our use of cookies.
            </Col>
            <Col
              sm={2}
              style={{
                textAlign: "right",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button className="font-weight-bold" onClick={agreeToU}>
                ACCEPT
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
};
