import React, { useState } from "react";
import style from "./style.scss";

export default ({ isOn, onClick, className }) => {
  return (
    <div
      onClick={onClick}
      className={
        className +
        " toggle" +
        (isOn ? " toggle-on bg-primary" : " bg-secondary")
      }
    >
      <div className={"toggle-switch"}></div>
    </div>
  );
};
