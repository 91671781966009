import React, { useState } from "react";
import Slide from "../../components/Slide";
import { useResetScroll } from "../../utils";
import style from "./style.scss";

export default (props) => {
  useResetScroll();

  return (
    <Slide className="pt-120">
      <p>
        <strong>Terms of Use Agreement</strong>
      </p>
      <p>
        Use of this website signifies your acceptance of WriterDuet Inc.'s
        <a href="/privacy"> privacy policy</a>,
        <a href="/community"> community guidelines</a>, and the terms of service
        agreement below.
      </p>
      <p>
        If you have questions about any of these agreements, you can contact us
        at help@writerduet.com.
      </p>
      <p>
        <strong>1. YOUR ACCEPTANCE OF THIS AGREEMENT</strong>
      </p>
      <p>
        This Terms of Use Agreement is an electronic contract between you and
        WRITERDUET INC.. All users of WRITERDUET INC. Websites agree to be
        legally bound by this Agreement, regardless of whether they register
        with WRITERDUET INC.. Nothing in this Agreement shall be deemed to give
        benefits or rights to any third party.
      </p>
      <p>
        Using or visiting the WRITERDUET INC. Websites or using any WRITERDUET
        INC. product or service (collectively, our "Service"), signifies your
        agreement to abide by: (1) the terms and conditions listed here (our
        "Terms of Use" or “this Agreement”); (2) the terms of WRITERDUET INC.'s
        Privacy Policy found at{" "}
        <a href="/privacy">https://writerduet.com/privacy.html</a>; and (3)
        WRITERDUET INC.'s Community Guidelines found at found at{" "}
        <a href="/community">https://writerduet.com/community.html</a>. Our
        Privacy Policy and Community Guidelines are incorporated by reference
        into these Terms of Use. You should not use our Service if you don't
        agree to any part of our Terms of Use, Privacy Policy, or Community
        Guidelines.
      </p>
      <p>
        We may alter the terms of this Agreement at any time by posting changes
        to our Terms of Use, Privacy Policy, or Community Guidelines on the
        WRITERDUET INC. Websites. Although we may try to notify you when we
        alter the terms of this Agreement, you should periodically review the
        most up-to-date versions. Your use of our Service or Website after we
        post any changes signifies your acceptance of all revisions to date,
        regardless of whether you receive actual notice of those changes.
      </p>
      <p>
        <strong>
          You must be at least 13 years of age to use this website. If you are
          under the age of 13 you are <u>not permitted</u> to use this website.
          If you are over the age of 13 but under the age of 18, use of this web
          site requires you to have the consent of your parent or guardian.
        </strong>
      </p>
      <p>
        <strong>2. HOW TO WITHDRAW YOUR CONSENT</strong>
      </p>
      <p>
        You have the right to withdraw your consent to this Agreement and revoke
        your WRITERDUET INC. registration at any time by deleting your account
        from your User Account page on our Website. Upon receipt of your notice,
        we will permanently delete your account and remove the information in
        your user profile from all publicly accessible WRITERDUET INC. web
        pages. This means that you will no longer be able to use the Service
        unless you re-register. However, relevant provisions of this Agreement
        that by their nature may survive its termination will remain in effect
        after you close your account. Furthermore, some data, as described in
        Section 7 below, may be retained and used by WRITERDUET INC. even after
        you terminate your registered account.
      </p>
      <p>
        <strong>3. HOW YOU SHOULD PROTECT YOUR PERSONAL INFORMATION</strong>
      </p>
      <p>
        When you register with WRITERDUET INC. as a user, you may be asked to
        provide us with personal or personally identifying information, such as
        your name, address, email address, physical address, and credit-card
        information. The ways we use and protect the confidentiality of this
        data are subject to the terms of our Privacy Policy, which you should
        thoroughly review before revealing posting any such information to
        WRITERDUET INC.
      </p>
      <p>
        You will be asked to select a password when you register. You are
        responsible for all activities performed by any person that occur under
        your account and are solely responsible for protecting the
        confidentiality of your password. Exercise caution when accessing your
        account from a public or shared computer and be sure that no one can
        view your password and other personal information displayed on your
        screen. If you share your computer, disable “Remember Me,” Autocomplete,
        and other features that may let other parties learn your confidential
        information. You should also be sure to log out of your WRITERDUET INC.
        account at the end of each session. WRITERDUET INC. is not liable for
        loss or damages arising from your failure to take any of these
        precautions.
      </p>
      <p>
        You promise to notify WRITERDUET INC. of any unauthorized use of your
        account or of any other breach of security as soon as you learn of it.
      </p>
      <p>
        <strong>4. NON-REFUNDABLE PAID SERVICES</strong>
      </p>
      <p>
        Some features of our Website and Service are available only to users who
        have registered and opened an account. You will be asked to set up a
        registered account and pay any associated fees before you can use
        related WRITERDUET INC. products and services. All payments made to
        WRITERDUET INC. are non-refundable, unless otherwise specified in
        writing.
      </p>
      <p>
        For some customers, primarily those located in Europe, our order process
        is conducted by our online reseller Paddle.com. Paddle.com is the
        Merchant of Record for some of our orders. Paddle provides all customer
        service inquiries and handles returns for those orders, which would be
        placed on the Paddle.co webs site.
      </p>
      <p>
        <strong>5. SUBMITTING A SCRIPT</strong>
      </p>
      <p>
        Registered members of WRITERDUET INC. who have written an original
        script (“Script Authors” or “Authors”) may upload their scripts for
        analysis, grammar correction, conversion to an audio file (the
        “Recording”), and other services performed by proprietary software owned
        by WRITERDUET INC. or by our Partners. Our Website lists any file-format
        or other types of compatibility requirements necessary to use any of our
        products or services.
      </p>
      <p>
        When you submit a script to WRITERDUET INC., you promise that:
        <ol>
          <li>
            You own all rights, including copyright, to the entire script or
            have been authorized by its copyright owner to submit it to
            WRITERDUET INC.;
          </li>
          <li>
            The content of the script is not unlawful, defamatory, libelous,
            threatening, obscene, pornographic, harassing, hateful, racially or
            ethnically offensive, or fraudulent, and doesn’t encourage conduct
            that would be considered a criminal offense, give rise to civil
            liability, or violate any law, or would be otherwise inappropriate;
          </li>
          <li>
            The content of the script does not violate any other rights of any
            party, such as infringing a copyright or trademark, publishing
            falsehoods or misrepresentations about WRITERDUET INC. or any other
            person or entity, or violating another person’s right to privacy or
            publicity;
          </li>
          <li>
            No other party has a pending claim to the script that, if upheld,
            would render our Service or products, or those of our Partners,
            illegal or in violation of any party's rights.
          </li>
        </ol>
      </p>
      <p>
        You retain all your prior ownership rights in your submitted scripts.
        However, by submitting your script to WRITERDUET INC., you grant us a
        worldwide, non-exclusive, royalty-free license to use, copy, reformat,
        translate, excerpt, distribute/publicly display (only as you permit, to
        guests, actors, people you submit your script to, and anyone else you
        explicitly choose) and perform your script in any media format and
        through any media channel to provide to you any current or future
        WRITERDUET INC. service or product. We will not act without your
        authorization, for example, to make your submitted script publicly
        viewable on our Website, to submit it to a studio, or to reveal it to
        other registered users. You also agree that your submitted scripts may
        be reviewed and processed by our and our Partners' automated systems and
        human reviewers, when doing so is necessary to provide our services or
        products to you.
      </p>
      <p>
        You agree that you will not suggest, directly or indirectly, that
        WRITERDUET INC. endorses the content of any script that you submit,
        including opinions, recommendations, or advice expressed by that script.
      </p>
      <p>
        You grant all other WRITERDUET INC. users a non-exclusive license to
        access your script through the authorized methods provided by our
        Service for personal, non-commercial consumption, and to use, reproduce,
        distribute, display and perform that script only as permitted by our
        Service and under the terms of this Agreement.
      </p>
      <p>
        WRITERDUET INC. makes no other guarantees or warranties regarding use of
        our Website or our Service and all of our products and services are
        provided “as-is.” While we will take all reasonable steps to protect
        your submitted script from unauthorized general publication or
        disclosure, we do not unequivocally guarantee that your submission will
        remain confidential or that its publication will be limited to you and
        your designated viewers.
      </p>
      <p>
        <strong>6. INDEMNIFICATION AND WARRANTIES</strong>
      </p>
      <p>
        You agree to indemnify, defend, and hold harmless WRITERDUET INC. and
        its subsidiaries, affiliates, officers, directors, owners, agents,
        information providers, licensors and licensees, advertisers, third-party
        content-providers and licensors, and other partners and employees,
        (collectively, the "Indemnified Parties") from any loss, liability,
        claim, or demand made by a third party and arising: 1) out of your use
        of the Service in violation of this Agreement; 2) from a breach of this
        Agreement; or 3) from any other breach of your representations and
        warranties listed above. This indemnification extends to the award of
        attorneys' fees in any legal dispute, except where prohibited by law.
      </p>
      <p>
        You will cooperate with us as fully as reasonably required in the
        defense against such claims. WRITERDUET INC. reserves the right, at its
        own expense, to assume the exclusive defense and control of any matter
        subject to indemnification by you.
      </p>
      <p>
        WRITERDUET INC.'s software, services, and products are provided as-is
        and we will take every reasonable step to ensure that they are reliable
        and secure. You agree that WRITERDUET INC. and the Indemnified Parties
        will not be liable to you or to any third party for losses caused by
        your use or reliance on information obtained through or content
        distributed by WRITERDUET INC.
      </p>
      <p>
        WRITERDUET INC. and the Indemnified Parties will not be liable for any
        direct, indirect, incidental, special, punitive, or consequential
        damages or injury: 1) arising from the use of or inability to use
        WRITERDUET INC.'s Websites, products, or services; 2) arising from the
        breach of any warranty; or 3) caused by an error, omission,
        interruption, deletion, defect, delay in operation or transmission,
        computer virus, communication line failure, failure to perform, or
        theft, destruction, hacking, cracking, or other unauthorized access to
        or alteration of the WRITERDUET INC. Websites or our Service, software,
        or other products. This holds true regardless of whether such claims are
        based on legal theories of contract, tort, negligence, strict liability,
        or any other cause of action, and regardless of whether hawse have
        actual knowledge of the possibility of such damages. If your
        jurisdiction restricts the right to limit liability in this way, this
        Section may not apply to you. But even in that case, our liability will
        not exceed the sum of $100US.
      </p>
      <p>
        <strong>7. RECORDINGS</strong>
      </p>
      <p>
        If you are a Script Author, you agree that Recordings of your submitted
        scripts, regardless of whether they contain the recorded voices of live
        actors, synthesized or sampled “voice font” recordings created by
        computer programs or other types of automated processes, or by some
        variation or combination of the two, are provided to you “as is” and
        solely for analytical purposes within the scope of our Service. As one
        component of an informational service, these Recordings are provided to
        help you evaluate and improve your submitted script, and are not
        themselves a deliverable product that may be owned by Script Authors or
        transferred by Authors to other parties.
      </p>
      <p>
        As a Script Author, you may download Recordings of your own submitted
        script only through a Download button or similar mechanism on our
        Website that is intended for that purpose and may use downloaded
        Recordings only for your personal, non-commercial use and consumption.
        To be clear, you may access, play, and share links to Recordings during
        the course of your personal use only to the extent intended and allowed
        by the functionality of our Service and as permitted by this Agreement.
        You may not otherwise modify, copy, remove from our Website, distribute
        (such as by webcasting), sell, license, or assign rights to these
        recordings in any manner and for any other use, commercial or
        non-commercial, without our prior written consent.
      </p>
      <p>
        You agree that voice fonts and recordings of voice fonts embedded in a
        Recording are the sole intellectual property of one or more of our
        text-to-speech service-provider Partners; and that nothing in this
        Agreement transfers to you any rights of ownership, reproduction, or use
        of such intellectual property rights or constitutes a license to use
        those voice fonts or Recordings that contain recordings of those fonts
        in ways other than those expressly allowed by this Agreement.
      </p>
      <p>
        Our text-to-speech service-provider Partners currently include, but are
        not limited to, Cepstral LLC (“VoiceForge”) of Pittsburgh, PA; iSpeech,
        Inc. of Newark, NJ; Wizzard Software Corporation of Pittsburg, PA; and
        AT&T of Dallas, TX. These Partners retain all rights to edit, copy,
        reproduce, distribute, transmit, broadcast, display, sell, license, or
        otherwise exploit the voice fonts that they own and those components of
        any content, including Recordings, that contains their voice fonts,
        whether created for either commercial or non-commercial purposes,
        subject to the rights of the script writers. Neither we nor our
        Partners, however, claim any copyright, trademark, trade secret rights
        in a submitted script other than what is necessary to deliver our
        Service to you and to maintain proper functioning and operation of our
        Website and Service.
      </p>
      <p>
        As a Script Author, you agree to grant WRITERDUET INC. and our Partners
        the right to edit, copy, reproduce, distribute, transmit, broadcast,
        display, or otherwise use any Recording made from your submitted script
        only when necessary to provide you the service, and only if doing so
        does not violate any intellectual property right that you hold in your
        script. If we do not use a submitted Recording on our Website promptly
        after upload, that does not mean that we waive the right to use it
        without notice at a later time.
      </p>
      <p>
        Script Authors may choose to have the voices of one or more live actors
        (“Voice Actors”) incorporated into a Recording of a script you submit.
        WRITERDUET INC. may also give Script Authors the ability to choose or
        confirm the choice of specific Voice Actors to read parts of a submitted
        script. As an Author, you agree to grant Voice Actors a non-exclusive
        license to view and read the contents of your uploaded script for the
        purpose of creating a Recording, if done with your consent and with the
        permission of WRITERDUET INC. and using the resources of our Website and
        Service . Voice Actors have no other rights in your submitted script or
        the performance thereof.
      </p>
      <p>
        If you are a Script Author, you agree that WRITERDUET INC. and our
        Partners are not responsible for any real or perceived financial losses,
        loss of opportunity, or other types of losses due to the quality of our
        Service, Website, or products, including the quality of any Recording or
        the results of our script analysis, rating, and correction tools.
      </p>
      <p>
        You also agree that WRITERDUET INC. and our Partners are not responsible
        for any real or perceived financial losses, loss of opportunity, or
        other types of losses caused by your or anyone else's reliance on
        information, including Recordings and script ratings or reviews: 1)
        posted by us on our Website; 2) transmitted to or posted on our Website
        by others, including members, guests, advertisers, featured guests,
        Voice Actors, or experts; or 3) transmitted to or by WRITERDUET INC. by
        other means.
      </p>
      <p>
        You agree not to circumvent, disable or otherwise interfere with
        security features of our Service or features that prevent or restrict
        unauthorized use or copying of Recordings or enforce limitations on use
        of our Website, Service, or products.
      </p>
      <p>
        WRITERDUET INC. does not guarantee the quality, accuracy, or usefulness
        for any purpose of Recordings. WRITERDUET INC. may remove or block
        access to any submitted content, including Recordings, that we deem to
        violate any term of this Agreement, including our Privacy Policy and
        Community Guidelines, or for any other reason. Mere creation of a
        Recording does not guarantee that WRITERDUET INC. will accept or use it
        in conjunction with any of our services or products, post it on our
        Website, distribute it to our Partners for further processing, or
        include it in any of our statistical analyses or reports.
      </p>
      <p>
        Authors and Voice Actors who participate in creating a Readthrough
        Recording guarantee that no part of their contributions to Readthrough
        Recording, including its textual component and related or accompanying
        content (such as photographs, drawings, other audio recordings, and
        video clips) violate any party's copyright, trademark, trade secret, or
        other intellectual property rights Authors and Voice Actors also
        guarantee that such Recording components do not violate our Terms of
        Use, Privacy Policy or Community Guidelines, including restrictions
        against the submission or recording of patently offensive, pornographic,
        or illegal content. WRITERDUET INC. may remove or block access to any
        submitted content, including Recordings, that we deem to violate any
        term of this Agreement, including our Privacy Policy and Community
        Guidelines, or for any other reason.
      </p>
      <p>
        WRITERDUET INC. is not responsible for damages due to breaches of
        contract, misrepresentation, fraud, or any other violation of the law or
        civil disagreement that occurs within the scope of any collaboration
        between Script Authors and Voice Actors. This is true regardless of
        whether the Authors and Voice Actors ever make actual contact with each
        other, either online or in person, or have formed any written or oral
        contract, or whether the alleged violation occurs within the scope of
        our Service. WRITERDUET INC., for example, assumes no responsibility if
        an Author tricks Voice Actors into contributing to the creation of an
        infringing recording by misrepresenting a stolen script as an original
        work. Nor would we be liable if, after or during the process of creating
        a Recording, a Voice Actor sells or distributes an Author’s script to a
        third party without authorization from the rightful owner.
      </p>
      <p>
        <strong>8. VOICE ACTORS</strong>
      </p>
      <p>
        Voice Actors (as defined in Section 7) are users of our Service and
        agree to abide by the terms of this Agreement, including our Privacy
        Policy and Community Guidelines.
      </p>
      <p>
        By consenting to this Agreement, Voice Actors agree that they claim no
        present or future rights of ownership in or control over any Readthrough
        Recording that contains a recording of their voices. In particular,
        Voice Actors agree to the limitations described in Sections 6 and 7
        above. Voice Actors may access, play, and share links to Readthrough
        Recordings only for their personal use and only to the extent intended
        and allowed by the functionality of our Service and as permitted by this
        Agreement. Voice Actors may not otherwise copy, modify, remove from our
        Website, distribute, sell, license, or assign rights to these recordings
        in any manner and for any use, commercial or non-commercial, without our
        prior written consent. This is true even if a Voice Actor improvises or
        makes other changes to the script when reading a part into a Readthrough
        Recording.
      </p>
      <p>
        Voice Actors acknowledge that nothing in this Agreement transfers to
        them any rights of ownership, reproduction, or use in the voice fonts or
        in the script incorporated into a Recording, or constitutes a license to
        use those rights in ways other than those expressly allowed by this
        Agreement.
      </p>
      <p>
        Voice Actors involved in creating a Recording guarantee that no part of
        the Recording that they create, including textual components and related
        or accompanying content (such as photographs, drawings, other audio
        recordings, and video clips) violate any party's valid copyright,
        trademark, trade secret, or other intellectual property rights. Voice
        Actors also guarantee that such components of Readthrough Recordings do
        not violate our Terms of Use Agreement, Privacy Policy or Community
        Guidelines, including restrictions against the submission or recording
        of patently offensive, pornographic, or illegal content.
      </p>
      <p>
        As described in Section 7, WRITERDUET INC. is not responsible for
        damages due to breach of contract, misrepresentation, fraud, or any
        other violation of the law or civil disagreement that occurs within the
        scope of any collaboration between Script Authors and Voice Actors.
      </p>
      <p>
        As a Voice Actor, you agree that WRITERDUET INC. and our Partners may
        use Recordings that contain your recorded voice in any way permitted by
        law, such as posting them on our Website or transmitting them to our
        business partners. If we do not use a submitted Readthrough Recording on
        our Website promptly after upload, that does not mean that we waive the
        right to use it without notice at a later time.
      </p>
      <p>
        Voice Actors have rights in neither the script that they read into a
        Recording nor in the recording itself and may not modify, copy,
        distribute (such as by webcasting), reuse, sell, assign, license, or
        sublicense Recordings without the written consent of WRITERDUET INC.,
        the Script Author, and, in some cases, our service-provider Partners. By
        agreeing to record their voices into a Recording, Voice Actors also
        assert that they understand that they may not be able to listen to or
        access online a Readthrough Recording that contains their voices,
        receive public credit for their voice acting, or take any part in
        deciding whether a Readthrough Recording will be selected for use within
        the scope of our Service.
      </p>
      <p>
        READHTHROUGH.COM may, in its sole discretion, choose to publicly
        identify the Voice Actors involved in the creation of each Readthrough
        Recording. We may also include metadata in our aggregated statistical
        analyses and reports to describe the Recordings, their content, and
        their creators. This data, and the Recordings themselves, may be
        retained and used by WRITERDUET INC. even after Authors and Voice Actors
        have terminated their registered accounts with our Service.
      </p>
      <p>
        WRITERDUET INC. may post our own and users' reviews and ratings of Voice
        Actors and is not liable for any damages to any party that result from
        the distribution of such information. We reserve the right to remove or
        block access to any reviews or rankings that violate our Community
        Guidelines.
      </p>
      <p>
        <strong>9. WE MAY MONITOR USER BEHAVIOR</strong>
      </p>
      <p>
        We reserve the right to delete, move, or edit anything that you transmit
        to WRITERDUET INC., including email messages, forum postings, and
        submitted content, that we deem to violate the terms of this Agreement,
        our Privacy Policy, or our Community Guidelines that violate any law,
        court order, or government regulation or that we feel are otherwise
        unacceptable or offensive.
      </p>
      <p>
        <strong>10. SERVICE INTERRUPTION</strong>
      </p>
      <p>
        You agree that WRITERDUET INC. is not liable for damages and losses due
        to interruption of our Service, regardless of the cause of the
        interruption.
      </p>
      <p>
        <strong>11. USER BACKUP POLICY</strong>
      </p>
      <p>
        <b>
          Backups of Scripts, Recordings, Documents, Notes, Comments, MP3s, and
          Multimedia (collectively referred to as “Content”) are your
          responsibility. Unless otherwise stated, you must ensure that you have
          made the proper provisions to create and store backups of all versions
          of your Content including, but not limited to, your original uploaded
          version, your edits, and all collaborative versions, in a secure
          location off site from the WriterDuet cloud storage drive.
        </b>
      </p>
      <p>
        <u>
          <b>DISCLAIMER</b>
        </u>
      </p>
      <p>
        YOU ARE USING THIS SERVICE AT YOUR OWN RISK AND RESPONSIBILITY. ANY
        CONTENT YOU INCLUDE IN UPLOADED PROJECTS ARE ACCESSIBLE TO ANYONE THE
        PROJECTS ARE SHARED WITH. YOU, THE USER, HAVE SOLE CONTROL OVER WHO THE
        PROJECTS ARE SHARED WITH. WRITERDUET HEREBY DISCLAIMS ALL RIGHT,
        RESPONSIBILITY, OR LIABILITY FOR THE MIS-USE, ILLEGAL USE, OR LOSS OF
        YOUR CONTENT BY YOURSELF OR OTHER USERS.
      </p>
      <p>
        <u>
          <i>
            No Liability for Disaster Recovery, Business Continuity, or Lost
            Content
          </i>
        </u>
      </p>
      <p>
        In the case where WRITERDUET INC. provides backup services as part of
        your active membership license and described as such in the features of
        the Service, such backups are not intended as disaster recovery or
        business continuity purposes and WRITERDUET INC. is not responsible for
        lost content.
      </p>
      <p>
        <u>
          <i>Back-up Retention Policy</i>
        </u>
      </p>
      <p>Copies of the backups will be available for a limited time only.</p>
      <ul>
        <li>
          Beginning on November 13th, 2020 and continuing thereafter, WRITERDUET
          INC. will delete any and all retained copies of backups of your
          Content, if any, automatically within sixty (60) days of your User’s
          Membership Account being terminated or a Project being deleted;
        </li>
        <li>
          Content cannot be recovered or restored after a Project has been
          removed and permanently deleted;
        </li>
        <li>
          WRITERDUET INC. cannot prevent User errors that result in accidentally
          removed, deleted or permanently deleted Content or Projects.
        </li>
        <li>
          You are solely responsible for familiarizing yourself with all of the
          management and control functions for Content and Projects within the
          WRITERDUET INC. Service prior to use of the Service, including but not
          limited to version control, copy or paste, export, saving, removal,
          deletion and all related functionality.
        </li>
      </ul>
      <p>
        <u>
          <i>Written Requests for Copies of Existing Backups</i>
        </u>
      </p>
      <p>
        Upon written request, WRITERDUET INC. may provide you an electronic copy
        of any retained backups of your Content that are then currently
        contained within the WRITERDUET INC. network as part of your active
        membership license. You shall only be authorized to request and obtain
        copies of retained backups for your Content, not Content for any other
        User.
      </p>
      <p>
        <u>
          <i>Termination</i>
        </u>
      </p>
      <p>
        If a User’s Membership Account is removed due to termination or
        deletion, WRITERDUET INC. cannot guarantee full access to retained
        backups of your Content. In any case, WRITERDUET INC. shall permanently
        destroy any remaining retained backups of your Content which may exist
        for the User’s account sixty (60) days from the date of termination or
        deletion of the User’s Membership Account.
      </p>
      <p>
        <strong>12. THIRD-PARTY MATERIAL AND WEBSITES</strong>
      </p>
      <p>
        When processing your submitted script, we may forward it to
        text-to-speech service-provider Partners. These Partners retain all
        rights as described in Sections 6 and 7. To be clear, WRITERDUET INC.
        and its Partners are not responsible for any loss or damages caused by
        your or anyone else's reliance on information: 1) posted by us on the
        WRITERDUET INC. Websites; 2) transmitted to or posted on the Website by
        others, including members, guests, advertisers, featured guests, Script
        Authors, Voice Actors, or experts; or 3) transmitted to or by WRITERDUET
        INC. through other means.
      </p>
      <p>
        Opinions, advice, statements, offers, reviews, or other information or
        content made available by third parties on or through our Website or
        Service are the property of their respective authors, who are solely
        responsible for their content. WRITERDUET INC. does not guarantee the
        accuracy, completeness, or usefulness of any such information, whether
        published by WRITERDUET INC., our Partners, or our users.
      </p>
      <p>
        The WRITERDUET INC. Websites contain links to other Internet sites and
        resources ("External Links"). You acknowledge that WRITERDUET INC. is
        not responsible for, and has no liability as a result of the
        availability or use of our External Links or the contents of the sites
        they link to. We suggest that you review the Terms of Use and Privacy
        Policy statements of any Website you visit by clicking one of our
        External Links.
      </p>
      <p>
        By uploading your script to WRITERDUET INC., you grant us the right to
        submit your scripts to our Partners for analysis or conversion.
        WRITERDUET INC. makes no representations regarding our Partners’
        services or their Websites and we are not responsible for any
        infringement by these parties of your rights in your script, of your
        privacy, or of any other legal rights you claim.
      </p>
      <p>
        <strong>
          13. CLAIMS OF COPYRIGHT INFRINGEMENT: TAKEDOWN NOTICES AND
          COUNTER-NOTICES
        </strong>
      </p>
      <p>
        If you believe that any material or content distributed or posted on
        WRITERDUET INC. infringes your copyright, we will remove it upon
        receiving a takedown notice from you, as described in the federal
        Digital Millennium Copyright Act § 512(c). You should send us the notice
        via email to help@writerduet.com, or by mail to:
        <br />
        <br />
        WRITERDUET INC.
        <br />
        5201 Encinitas Ln
        <br />
        Austin, TX 78749
        <br />
        <br />
        WRITERDUET INC. will evaluate your claim and, if it appears reasonable,
        will remove the offending content from our Website.
      </p>
      <p>
        Legally proper takedown notices must include the six pieces of
        information listed below. If your notice does not substantially comply
        with this requirement, your rights as a copyright holder may be
        compromised. Your notice must include:
        <ol>
          <li>
            A copy of the work you claim to be infringed. If you claim
            infringement of multiple works, you should provide a representative
            list that contains identifying information for each item;
          </li>
          <li>The URL of the page on which the material is posted;</li>
          <li>
            Your contact information including your address, telephone number,
            email address;
          </li>
          <li>
            A statement that you have a good-faith belief that the allegedly
            infringing material has been posted or distributed without
            authorization by the copyright owner, its agents, or a relevant law
            or government regulation;
          </li>
          <li>
            A statement that “under penalty of perjury, the information
            contained in this notification is accurate” and that you are (or are
            authorized to act on behalf of) the true copyright owner;
          </li>
          <li>
            Your physical or electronic signature. A brief description of
            electronic signatures can be found in the Wikipedia at:
            http://en.wikipedia.org/wiki/Electronic_signature.
          </li>
        </ol>
      </p>
      <p>
        When we receive a proper takedown notice, we immediately launch an
        investigation. If we find any reasonable chance that the material you
        identify does indeed infringe a valid copyright, we will remove that
        material from our Website or block access to it. We'll also promptly
        notify the person who posted it. Our goal will be to remove the content
        within 48 hours of receiving proper notice, but in complicated
        situations, this process could take as long as two weeks.
      </p>
      <p>
        If we remove or block material that you have posted because another
        person has claimed that it infringes a valid copyright, we will give you
        the chance to contest that claim in a counter-notice. You should
        promptly send the counter-notice to us through any of the acceptable
        methods for sending takedown notices. If your counter-notice
        substantially complies with the legal requirements listed below, we will
        restore your material no later than 10-14 days of receipt, regardless of
        whether it is ultimately determined that your material actually does
        infringe. If the person filing the original takedown notice sues you in
        court, however, we cannot restore your material until the court case
        concludes.
      </p>
      <p>
        Your counter-notice must include:
        <ol>
          <li>
            Reasonable identification of the material that has been removed or
            blocked and the URL of the page where it been posted;
          </li>
          <li>
            A statement that says that under penalty of perjury you have a
            good-faith belief that the material was removed or blocked because
            of a mistake or misidentification;
          </li>
          <li>
            Your contact information, including your address, telephone number,
            email address;
          </li>
          <li>
            A statement that you consent to the jurisdiction of the Federal
            District Court for the judicial district in which your physical
            address is located. If you don't live in the United States, you may
            specify any federal judicial district in which WRITERDUET INC. may
            be found. You must also state that you consent to accept service of
            process from the person who submitted the takedown notice “in
            compliance with section (c)(1)(C) of the Digital Millennium
            Copyright Act”;
          </li>
          <li>Your physical or electronic signature.</li>
        </ol>
      </p>
      <p>
        <strong>14. CHANGES WE MAKE TO OUR SERVICE</strong>
      </p>
      <p>
        We may alter, suspend, discontinue, or add terms to any aspect of the
        WRITERDUET INC. Websites, our Service, or our other products at any time
        without liability or notice to you. We may limit the availability of any
        Service feature, database information, or other content and may restrict
        your access to parts or all of the Service without liability or notice .
      </p>
      <p>
        <strong>15. CHOICE OF VENUE FOR LEGAL DISPUTES</strong>
      </p>
      <p>
        If you become involved in a legal dispute with WRITERDUET INC. or in a
        legal dispute that arises out of your or another party's use of our Web
        site, products, or Service, you agree that the dispute will be governed
        by the laws of the State of Texas, regardless of Texas's conflict-of-law
        rules. You also consent to the exclusive jurisdiction and venue of the
        state and federal courts of the State of Texas, in Travis County, for
        the resolution of that dispute.
      </p>
      <p>
        <strong>16. STATUTE OF LIMITATIONS</strong>
      </p>
      <p>
        You agree that, regardless of any law to the contrary, any claim or
        cause of action arising from or related to the use of our Service, Web
        site, or other products that you don't file within one year after it
        arose will be forever barred. The failure of any party to exercise a
        right provided by this Agreement is not a waiver of further rights under
        the terms of the Agreement.
      </p>
      <p>
        <strong>17. INTEGRATION & THIRD-PARTY BENEFICIARIES</strong>
      </p>
      <p>
        If any provision of this Agreement is found to be unenforceable or
        invalid, that provision will be limited or eliminated to the minimum
        extent necessary to permit the rest of the Agreement to remain in
        effect. This Agreement may be assigned in whole or in part, transferred,
        or sublicensed only with WRITERDUET INC.'s prior written consent. This
        Agreement does not create any agency, partnership, joint venture, or
        employment relationship.
      </p>
      <p>
        This Agreement creates no third-party beneficiaries, except those that
        may be expressly created by one of its terms. These Terms of Service and
        the incorporated Privacy Policy and Community Guidelines together
        contain the entire agreement between you and WRITERDUET INC. regarding
        the use of our Website, Service, and other products.
      </p>
    </Slide>
  );
};
