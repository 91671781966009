import React from "react";
import Slide from "../../../components/Slide";
import {
  //   Jumbotron,
  //   Button,
  //   Container,
  Row,
  Col,
  Image,
  //   Badge,
  //   Collapse,
} from "react-bootstrap";
import typewriter from "../action-adult-analog-1043512.jpg"; // Tell webpack this JS file uses this image

export default (props) => {
  return (
    <Slide>
      <h3 className="font-weight-bold text-center mb-5">
        Senior Front-end Engineer
      </h3>

      <Row>
        <Col lg={6}>
          <Image src={typewriter} fluid />
        </Col>
        <Col lg={6}>
          <div>
            <br />
            WriterDuet puts the magic of screenwriting into everyone’s hands.
            Our real-time collaborative software can be found in the hands of
            student, aspiring, and professional writers alike. Some of the
            biggest movies, TV shows, and video games in the world have been
            written on WriterDuet!
            <br />
            <br />
            Here at WriterDuet, we thrive at the intersection of software
            engineering and creative writing. We take pride in designing
            beautiful and intuitive interfaces for ALL writers and supporting
            them throughout the entire creative journey.
            <br />
            <br />
            WriterDuet is a profitable Techstars company based in Austin, TX. We
            value curiosity, resilience, and a sense of humor. Our team is
            authentically built with film/writing lovers and engineering nerds,
            and we know there are other uniquely talented and like-minded
            programmers out there who want join our adventure!
          </div>
        </Col>
      </Row>
      <div>
        <br />
        Send your resume and cover letter to{" "}
        <a href="mailto:jobs@writerduet.com">jobs@writerduet.com</a>. Include a
        link to something you've created, plus tell us why you'd be a great fit!
        <br />
        <br />
        <em>
          We are a remote team, so this job is not dependent on location. That
          said, much of our team is currently based in Austin, and many of our{" "}
          <i>writers</i> are in Los Angeles, so we have a slight preference for
          those cities.
        </em>
      </div>
      <div>
        <hr />
        <div class="Slugline">Responsibilities:</div>
        {/* support: */}
        {/* <ul>
          <li>
            Work with users via messaging and phone to quickly identify
            problems, recommend solutions, and dramatically improve WriterDuet’s
            brand identity for that user
          </li>
          <li>
            Update self-help documents so users can more effectively solve
            problems themselves
          </li>
          <li>
            Escalate issues to relevant team members clearly and effectively,
            following up whenever necessary
          </li>
          <li>
            Test the product and isolate issues through reproduction and
            observation
          </li>
          <li>Identify common trends and underlying problems</li>
          <li>Act as the primary advocate for our user base</li>
          <li>
            Be creative and offer suggestions about features and product
            direction
          </li>
          <li>
            Learn as much as possible, tailored to your interests and skills
          </li>
        </ul> */}
        {/* sr. frontend and devops engineer */}
        <ul>
          <li>Create engaging and impactful UI components and applications</li>
          <li>
            Collaborate with other team members, including our UI/UX and Product
            teams, to help influence our current tools and applications
          </li>
          <li>Build, fix, optimize, and improve React components</li>
          <li>
            Join the effort to build, release and maintain iOS and Android apps
            in Cordova
          </li>
          <li>
            Manage your work through the use of Github and our build/deploy
            systems (Bitrise, Netifly, Firebase)
          </li>
          <li>Work with our QA team to test existing and new UI components</li>
          <li>
            Help resolve technical issues/blockers to keep WriterDuet running
            smoothly
          </li>
          <li>
            Work with everyone across the company to problem-solve complex
            issues, including non-technical
          </li>
        </ul>
        <div class="Slugline">Job Type:</div>
        <ul>
          <li>Full-time</li>
        </ul>
        <div class="Slugline">Salary:</div>
        <ul>
          <li>
            Based on your level of experience, you should expect a starting
            salary in the range of $130,000 to $170,000/year (with a potential
            adjustment after 3 months, as well as possible stock options).
          </li>
        </ul>
        {/* cut: */}
        {/* <div class="Slugline">What we offer</div> */}
        {/* <ul>
          <li>Excellent health insurance</li>
          <li>Flexible working schedule</li>
          <li>
            The chance to have a profound impact on the effectiveness and
            direction of a small, fast-growing business
          </li>
          <li>
            A team that encourages asking questions about everything (even if
            that knowledge isn't necessary for your current role), and genuinely
            wants to help you reach your personal goals at WriterDuet and beyond
          </li>
        </ul> */}
        <div class="Slugline">Qualifications:</div>
        {/* Support: */}
        {/* <ul>
          <li>Background supporting customers or clients</li>
          <li>Experience with creative collaboration</li>
          <li>
            Knowledge of WriterDuet (you should at least check it out before
            applying)
          </li>
        </ul> */}
        <ul>
          <li>
            5+ years or equivalent experience in a front-end development role
          </li>
          <li>Proficient in React and MobX</li>
          <li>Background in UI/UX-focused work</li>
          <li>
            Superior collaboration skills – we value and expect input from
            everyone
          </li>
          <li>A true aptitude for efficient and clever problem-solving</li>
          <li>
            Respect for quality, keeping the end-users’ experience top-of-mind{" "}
          </li>
          <li>
            Excitement about WriterDuet, and desire to help writers create
            better
          </li>
        </ul>
        <div class="Slugline">Preferred qualifications:</div>
        {/* support: */}
        {/* <ul>
          <li>
            Experience with/passion for creative writing, scriptwriting, and/or
            filmmaking (there's likely room for content creation in this role)
          </li>
          <li>Experience with a technology company</li>
          <li>
            Interest in data/analytics, or at least a desire to measurably move
            meaningful metrics
          </li>
        </ul> */}
        {/* Sr. Front-end & devOps Engineer */}
        <ul>
          <li>Proficient in Electron, Cordova, and React Native</li>
          <li>Experience with Firebase (especially real-time database)</li>
          <li>
            Experience with wearing many hats, working efficiently, and
            maximizing impact every day
          </li>
          <li>Experience working within (and improving) complex systems</li>
        </ul>
        <div class="Slugline">Our values:</div>
        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>Collaboration</span>. We offer
            and request assistance often to iterate more effectively, save time,
            and uncover insights.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Ownership</span>. We encourage
            team members to stand up and announce responsibilities by setting
            goals, supporting visions, and celebrating successes and failures.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Growth</span>. At WriterDuet,
            employees can find success in their daily tasks and expand skills in
            additional roles by controlling their responsibilities and what they
            learn.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Quality</span>. We measure
            decisions, make sustainable systems, and build innovative
            technology.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Enjoyment & Fulfillment</span>.
            The company embraces team collaboration, individual ownership,
            personal growth, and quality to provide an inspiring, happy, and
            creative workplace.
          </li>
        </ul>
        <div class="Slugline">
          <b>Our commitment:</b>
        </div>
        WriterDuet is committed to providing a friendly, safe and welcoming
        environment for all, regardless of gender, gender identity, gender
        expression, sexual orientation, disability, physical appearance, body
        size, age, race, ability, ethnicity, socioeconomic status, religion (or
        lack thereof), or veteran status. WriterDuet does not tolerate
        harassment or discrimination in any form. Above and beyond
        discrimination/harassment based on “protected categories,” WriterDuet
        also strives to prevent other, subtler forms of inappropriate behavior
        (e.g. stereotyping) from ever gaining a foothold in our office. Whether
        blatant or hidden, barriers to success have no place at WriterDuet.
      </div>
    </Slide>
  );
};
