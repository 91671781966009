import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import feature1 from "../../assets/images/feature1.png";
import feature2 from "../../assets/images/feature2.png";
import feature3 from "../../assets/images/feature3.png";

export default () => {
  return (
    <>
      <section className="text-center features-list">
        <Container>
          <h2 className="font-weight-bold mb-md-5 mb-2 pb-2">
            <span style={{ color: "#4CA9ED" }}> Hassle-free</span> professional
            scriptwriting software
          </h2>

          <Row>
            <Col lg lg="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={feature1} />
                <Card.Body>
                  <Card.Title>Industry Standard</Card.Title>
                  <Card.Text>
                    <p>Templates and custom formatting</p>
                    <p>Locked and colored pages</p>
                    <p>Import/export .fdx files (and more)</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col lg lg="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={feature2} />
                <Card.Body>
                  <Card.Title>Collaborative</Card.Title>
                  <Card.Text>
                    <p>Co-write in real-time or separately</p>
                    <p>Text/video chat, comments/replies</p>
                    <p>Share or publish for feedback</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col lg lg="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={feature3} />
                <Card.Body>
                  <Card.Title>Remembers Everything</Card.Title>
                  <Card.Text>
                    <p>Production &amp; writer revisions</p>
                    <p>Individual line history</p>
                    <p>Edit-by-edit timeline</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
