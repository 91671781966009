import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import feature1 from "../../assets/images/feature1.png";
import feature2 from "../../assets/images/feature2.png";
import feature3 from "../../assets/images/feature3.png";

export default () => {
  return (
    <>
      <section className="text-center features-list">
        <Container>
          <h2 className="font-weight-bold mb-md-5 mb-2 pb-2">
            كتابة النصوص السينمائية بمنتهي السهولة و الحرفية
          </h2>

          <Row>
            <Col lg lg="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={feature1} />
                <Card.Body>
                  <Card.Title>قوالب المحترفين </Card.Title>
                  <Card.Text>
                    <p>قوالب آحترافية </p>
                    <p>صفحات ملونة و مؤمنة</p>
                    <p>آدخال و حفظ بمختلف الصيغات</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col lg lg="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={feature2} />
                <Card.Body>
                  <Card.Title>ورش العمل</Card.Title>
                  <Card.Text>
                    <p>فريق الكتابة يعمل في نفس اللحظة و بتصنيفات مختلفة </p>
                    <p>
                      Tمحادثة صوتية و مرئية مع تعليقات لكل اعضاء فريق الكتابة
                    </p>
                    <p>مشاركة و طلب آراء كتاب آخرين.</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col lg lg="4">
              <Card style={{ border: "none" }}>
                <Card.Img variant="top" src={feature3} />
                <Card.Body>
                  <Card.Title> مساحة تخزين غير محدودة </Card.Title>
                  <Card.Text>
                    <p>مراجعة الكتاب و مسؤلي الآنتاج الفني</p>
                    <p>حفظ كامل لكل كلمة مكتوبة</p>
                    <p>حفظ و مراجعة كل مراحل الكتابة و التعديل</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
