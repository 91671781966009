import React from "react";
import { Navbar, NavDropdown, Nav, Container } from "react-bootstrap";
import { Router, Link } from "@reach/router";

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "nav-link active" } : {};
};

export default ({ to, href, children }) =>
  href ? (
    <Nav.Link href={href}>{children}</Nav.Link>
  ) : (
    <Link getProps={isActive} className="nav-link" to={to}>
      {children}
    </Link>
  );
